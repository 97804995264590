import { Injectable } from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { Storage } from '@ionic/storage';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private storage: Storage,) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
//     // Get the auth token from the service.
let tockenData:any
    // this.storage.get('tocken').then(data =>{
    //   tockenData=data;
    // });
//     const authReq = req.clone({ setHeaders: { Authorization: authToken } });
    return next.handle(req);
  }
}
