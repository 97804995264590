import { Component, OnInit, Input, EventEmitter, Output, NgZone } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { HttpService } from 'src/app/services/http/http.service';
import { ActivatedRoute } from '@angular/router';
import { Storage } from '@ionic/storage';
import { LanguageService } from 'src/app/services/language/language.service';
import { ApigetService } from 'src/app/services/apiget/apiget.service';
import { TranslateService } from '@ngx-translate/core';
import { PhoneValidaterService } from 'src/app/phone-validater.service';
import { ToastController, Events } from '@ionic/angular';
import { BuymembershipService } from 'src/app/buymembership.service';
import { IonSlides, LoadingController, AlertController, MenuController } from '@ionic/angular';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { StateStore } from 'src/app/pages/buy-membership/state.store';



@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss', './profile.component.rtl.scss'],
})
export class ProfileComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private storage: Storage, private HTPS: HttpService, private route: ActivatedRoute, public languageService: LanguageService,
    private apiService: ApigetService, private translateService: TranslateService, public PhoneValidater: PhoneValidaterService, private toastController: ToastController, public events: Events,
    private loadingController: LoadingController,
    public buymembershipservice: BuymembershipService,
    public zone: NgZone,
    public sanitizer: DomSanitizer,
    public stateStore: StateStore
  ) {}

  profileupdate: FormGroup;
  public uploader: FileUploader = new FileUploader({});

  validation_messages = {
    'emergencyContactName': [
      { type: 'required', message: 'EmergencyContactNameErr' },
      { type: 'pattern', message: 'NameShouldAcceptChars' }
    ],
    'emergencyContactNumber': [
      { type: 'required', message: 'EmergencyContactNumberErr' }
    ],
    'nationality': [
      { type: 'required', message: 'NationalityErr' },
    ],
    'selectId': [
      { type: 'required', message: 'NationalIqamaIdErr' },
      { type: 'minlength', message: 'NationIqamaLength' }
    ],
    'nationalitytype': [
      { type: 'required', message: 'NationalityTypeErr' },
    ]
  }

  Stage: any = ['product'];
  imgarray: any = [];
  requiredDocFiles = [];
  memberEmail: any;
  memberMandatoryFields: string[];
  manditoryApiCalled: boolean;
  offerDetails: any = [];
  isDocUpload: boolean;
  nationalitytype: any;
  selectId: any;
  nationalityJson: any;
  NationalityJson: any;
  Nationality: any;
  phoneNumber: string;
  mobileNumberValidation: String;
  ismobilenovalid: boolean;
  defaultCode: any;
  imageErorMsg: string;
  duplicateIqamaID: string;
  duplicateNationalID: any;
  email: any;
  nationality: any;
  dob: any;
  emergencyContactName: any;
  emergencyContactNumber: any;
  byPassMobileCheck: boolean;
  movetoCart: boolean;

  @Output() pushStage = new EventEmitter<any>();

  @Input()
  set Mandatory(mandatory) {
    this.memberMandatoryFields = mandatory;
    if (this.memberMandatoryFields[0] != "") {
      this.manditoryApiCalled = true;
      this.profileupdate = this.formBuilder.group({
        emergencyContactName: ['', Validators.compose([Validators.maxLength(100), Validators.pattern('^[\u0600-\u06FFa-zA-Z ]*$'), this.mandatoryfielderequired('emergencyContactName')])],
        emergencyContactNumber: ['', Validators.compose([this.mandatoryfielderequired('emergencyContactNumber')])],
        nationality: ['', Validators.compose([Validators.maxLength(30), this.mandatoryfielderequired('nationality')])],
        selectId: ['', Validators.compose([Validators.maxLength(30), Validators.minLength(10), this.mandatoryfielderequired('nationalid')])],
        nationalitytype: ['', Validators.compose([this.mandatoryfielderequired('nationalid')])]
      });
      this.profileupdate.get('nationalitytype').setValue('National ID');
    }
  }

  @Input()
  set offerCheck(offer) {
    if (offer) {
      this.offerDetails = offer
      if (this.memberMandatoryFields[0] != "" || (this.offerDetails && this.offerDetails.OfferDocumentsRequired && this.offerDetails.OfferDocumentsRequired.length > 0 && this.offerDetails.OfferDocumentsRequired.filter(a => a['Available'] == 'False').length > 0)) {
        if (this.offerDetails && this.offerDetails.OfferDocumentsRequired && this.offerDetails.OfferDocumentsRequired.length > 0 && this.offerDetails.OfferDocumentsRequired.filter(a => a['Available'] == 'False').length > 0) {
          this.isDocUpload = true;
        }
        else {
          this.isDocUpload = false;
        }
      }
    }
    else this.offerDetails = []
  }

  @Input()
  set defaultcode(value) { this.defaultCode = value }

  @Input()
  set movecart(vv) {
    this.movetoCart = vv
    if (vv) {
      let status: boolean = this.onSubmit();
      if (status) {
        this.storage.get('WaiverStatus').then(val => {
          if (val == true) {
          } else {
            this.storage.set('waiveraccepted', false);
          }
        });
      }
      else {
        this.validateAllFormFields(this.profileupdate)
      }
    }
  }

  ionViewWillEnter() {
    this.imgarray = [];
  }

  ngOnInit() {
    this.events.subscribe('submitProfile', () => {
      let status = this.onSubmit()
      this.events.publish('status', status)
    });

    this.profileupdate = this.formBuilder.group({
      emergencyContactName: ['', Validators.compose([Validators.maxLength(100), Validators.pattern('^[\u0600-\u06FFa-zA-Z ]*$'), Validators.required])],
      emergencyContactNumber: ['', Validators.compose([Validators.required])],
      nationality: ['', Validators.compose([Validators.maxLength(30), Validators.required])],
      selectId: ['', Validators.compose([Validators.maxLength(10), Validators.minLength(10), Validators.pattern('^[0-9]*$'), Validators.required])],
      nationalitytype: ['', Validators.compose([Validators.required])]
    });

    this.translateService.get('SELECTNATIONALITY').subscribe(value => {
      this.Nationality = value;
    })
    this.GetNationalityData();
    this.storage.get('profileData').then(profile => {
      this.memberEmail = profile.emailID;
    })
  }

  get movecart(): boolean { return this.movetoCart!; }

  getImage(formname: string, formid: number, i) {
    let othis = this;
    let file: File;
    let ext: string;
    let reader;
    let Imgstring: string;
    let obj;
    let filejson = this.requiredDocFiles;
    this.uploader.queue.map((fileItem) => {
      obj = fileItem.file;
      reader = new FileReader();
      let email = this.memberEmail;
      file = obj.rawFile as any;
      ext = obj.name.substring(obj.name.lastIndexOf('.') + 1);
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        let emailid = email;
        Imgstring = reader.result.toString();
        this.zone.run(() => {
          if (Imgstring != '') {
            this.imgarray.push(Imgstring);
          }
        });
        filejson.push({ "formId": formid, "fileName": obj.name, "emailid": emailid, "formName": formname, "base64String": Imgstring });
      }
      reader.onloadend = function () {
        if (filejson.length > 0) {
          filejson = filejson.filter(p => p.base64String != "");
          var uniqueUsers = _.uniqBy(filejson, "formId");
          othis.requiredDocFiles = uniqueUsers;
        }
        else {
          othis.requiredDocFiles = filejson;
        }
      };
      reader.onerror = function (error) {
      };
    });
  }

  GetNationalityData() {
    this.apiService.getNationality().subscribe(response => {
      this.NationalityJson = _.chain(response)
        .map(el => ({ id: el['Nationality'], name: el['Nationality'] }))
        .sortBy('name')
        .value();
    });
  }

  handleEvent(e: KeyboardEvent) {
    let el = e.target as HTMLInputElement;
    const patterns: { [key: string]: string } = {
      alpha: "^[\u0600-\u06FFa-zA-Z ]*$",
      numeric: "^[0-9]*$"
    };
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      e.preventDefault();
    } else {
      if (Object.keys(patterns).includes('numeric')) {
        let regex = new RegExp(patterns['numeric']);
        if (!regex.test(el.value)) {
          el.value = el.value.slice(0, -1);
          this.phoneNumber = "";
        }
      }
      return true;
    }
  }

  selectNationality(evenet: any) {
    this.profileupdate.get('nationality').setValue(evenet.value.name);
    this.Nationality = evenet.value.name;
  }

  checkValidNumber() {
    console.log("inside check function")
    this.ismobilenovalid = true
    this.mobileNumberValidation = ''
  }

  CheckMobileNoValid() {
    let status: boolean;
    if (this.profileupdate.value.emergencyContactNumber != "") {
      status = this.PhoneValidater.isMobileNoValid(this.defaultCode, this.profileupdate.value.emergencyContactNumber);
      if (status) {
        this.ismobilenovalid = true;
        this.mobileNumberValidation = '';
        console.log('valid number');
      } else {
        status = false;
        this.ismobilenovalid = false;
        if (this.profileupdate.value.emergencyContactNumber.length > 0) {
          this.translateService.get('INVALIDPHONE').subscribe(value => {
            this.mobileNumberValidation = value;
          })
        }
      }
    }
    else {
      status = true;
      this.ismobilenovalid = true;
      this.mobileNumberValidation = '';
    }
    return status;
  }

  mandatoryfieldexists(val: string) {
    let status: boolean = false;
    status = _(this.memberMandatoryFields)
      .map(mandatoryFields => mandatoryFields.toLowerCase())
      .includes(val.toLowerCase());
    return status;
  }

  filterAvailbleOffer(offer) {
    if (offer == '') return;
    return offer.filter(a => a['Available'] == 'False')
  }

  checkid() {
    let formValues: any = this.profileupdate.value;
    if (this.nationalitytype != formValues.nationalitytype) {
      this.nationalitytype = formValues.nationalitytype;
      this.selectId = formValues.selectId;
      this.selectId = this.profileupdate.get('selectId')
      console.log("nationalityTypeeeeeeeeee", this.selectId, this.nationalitytype)
      this.selectId.reset();
    }
    return this.nationalitytype;
  }

  mandatoryfielderequired(val: string) {
    let status: any;
    status = _(this.memberMandatoryFields)
      .map(mandatoryFields => mandatoryFields.toLowerCase())
      .includes(val.toLowerCase())
      ? Validators.required : null;
    return status;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  
      const control = formGroup.get(field);             
      if (control instanceof FormControl) {             
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {       
        this.validateAllFormFields(control);            
      }
    });
  }

  onSubmit() {
    let status: boolean = false;
    if (this.memberMandatoryFields.length == 0 && (this.offerDetails && this.offerDetails.OfferId)) {
      if (this.offerDetails.OfferDocumentsRequired.length > 0) {
        let docStatus: boolean = this.CheckRequiredDocs();
        if (docStatus) {
          this.storage.get('tocken').then((val) => {
            let tocken: string = val;
            this.HTPS.UploadDocuments(this.requiredDocFiles, tocken).subscribe((response) => {
              this.offerDetails.OfferDocumentsRequired = "";
              this.stateStore.onStatechange()
            });
          });
        }
        else {
          status = false;
        }
      }
      else {
        status = true;
      }
    }
    else if (this.memberMandatoryFields.length > 0 && this.offerDetails.OfferId) {
      this.CheckMobileNoValid();
      let docStatus: boolean = this.CheckRequiredDocs();
      this.byPassMobileCheck = this.mandatoryfieldexists('emergencyContactNumber') && this.profileupdate.value.emergencyContactNumber ? false : true;
      if (this.profileupdate.value.emergencyContactNumber) {
        this.byPassMobileCheck = false;
      } else {
        this.mandatoryfieldexists('emergencyContactNumber') ? this.byPassMobileCheck = false : this.byPassMobileCheck = true;
      }
      if (this.profileupdate.valid && docStatus && (this.PhoneValidater.isMobileNoValid(this.defaultCode, this.profileupdate.value.emergencyContactNumber) || this.byPassMobileCheck)) {
        this.buymembershipservice.present();
        let _data = this.GetProfileData();
        let tocken: string;
        this.storage.get('tocken').then((val) => {
          tocken = val;
          this.HTPS.DashboardPutClientProfile(_data, tocken).subscribe((response) => {
            if (response.status == 500) {
              this.buymembershipservice.dismiss();
              this.ShowErrorMessage();
            }
            else {
              status = true;
              this.stateStore.setMandatoryFields([]);
              if (this.offerDetails && this.offerDetails.OfferDocumentsRequired.length > 0) {
                let requiredDocs: any = this.offerDetails.OfferDocumentsRequired.filter(a => a['Available'] == 'False');
                if (requiredDocs.length > 0) {
                  this.HTPS.UploadDocuments(this.requiredDocFiles, tocken).subscribe((response) => {
                    this.offerDetails.OfferDocumentsRequired = "";
                    this.stateStore.setIsDocUpload(false)
                    this.stateStore.onStatechange();
                  });
                }
              }
              else {
                this.buymembershipservice.dismiss()
                this.stateStore.onStatechange()
              }
            }
          });
        });
      }
      else {
        status = false;
        this.validateAllFormFields(this.profileupdate);
      }
    }
    else {
      this.CheckMobileNoValid();
      if (this.profileupdate.value.emergencyContactNumber) {
        this.byPassMobileCheck = false;
      } else {
        this.mandatoryfieldexists('emergencyContactNumber') ? this.byPassMobileCheck = false : this.byPassMobileCheck = true;
      }
      if (this.profileupdate.valid && (this.PhoneValidater.isMobileNoValid(this.defaultCode, this.profileupdate.value.emergencyContactNumber) || this.byPassMobileCheck)) {
        this.buymembershipservice.present();
        let _data = this.GetProfileData();
        let tocken: string;
        this.storage.get('tocken').then((val) => {
          tocken = val;
          this.HTPS.DashboardPutClientProfile(_data, tocken).subscribe((response) => {
            if (response.status == 500) {
              this.ShowErrorMessage();
            }
            else {
              status = true;
              this.pushStage.emit(true)
              this.stateStore.setMandatoryFields([]);
              console.log(response.data.data);
              this.buymembershipservice.dismiss();
              this.stateStore.onStatechange()
            }
          });
        });
      }
      else {
        status = false;
        this.validateAllFormFields(this.profileupdate);
      }
    }
    this.events.publish('status', status)
    return status;
  }

  //Method to get prifle data
  GetProfileData() {
    let formValues: any = this.profileupdate.value;
    this.emergencyContactName = formValues.emergencyContactName;
    this.emergencyContactNumber = formValues.emergencyContactNumber;
    this.nationality = formValues.nationality;
    this.selectId = formValues.selectId;
    this.nationalitytype = formValues.nationalitytype;
    let _data: any = {};
    let cnt: number = this.memberMandatoryFields.length;
    for (let i = 0; i <= cnt; i++) {
      let objkey: string = String(this.memberMandatoryFields[i])
        .trim()
        .toLowerCase();
      switch (objkey) {
        case "emergencycontactnumber":
          _data.emergencyContactNumber = this.emergencyContactNumber;
          break;
        case "emergencycontactname":
          _data.emergencyContactName = this.emergencyContactName;
          break;
        case "dob":
          _data.dob = this.dob;
          break;
        case "nationalid":
          _data.nationalid = this.selectId;
          _data.nationalOrIqama = this.nationalitytype;
          break;
        case "nationality":
          _data.nationality = this.nationality;
          break;
        case "email":
          _data.emailAddress = this.email;
          break;
      }
    }
    return _data;
  }

  ShowErrorMessage() {
    if (this.nationalitytype == "National ID") {
      this.translateService.get('DUPLICATENATIONALID').subscribe(
        value => {
          this.duplicateNationalID = value;
        });
      this.buymembershipservice.toastLoading(this.duplicateNationalID);
    }
    if (this.nationalitytype == "Iqama ID") {
      this.translateService.get('DUPLICATEIQAMAID').subscribe(
        value => {
          this.duplicateIqamaID = value;
        });
      this.buymembershipservice.toastLoading(this.duplicateIqamaID);
    }
  }
  // ================================================================================

  CheckRequiredDocs() {
    let status: boolean = false;
    if (this.offerDetails && this.offerDetails.OfferDocumentsRequired) {
      if (this.offerDetails && this.offerDetails.OfferDocumentsRequired.length > 0) {
        let files = this.requiredDocFiles;
        // let validFiles = this.offerDetails.OfferDocumentsRequired.filter(a=>a['Available'] =='False');
        let requiredDocs: any = this.offerDetails.OfferDocumentsRequired.filter(a => a['Available'] == 'False');
        if (files.length == requiredDocs.length) {
          let isvalidfiles: boolean = this.validateFile(files);
          if (isvalidfiles) {
            this.imageErorMsg = '';
            status = true;
          }
          else {
            this.imageErorMsg = 'Invalid file';
            status = false;
          }
        }
        else {
          let selectFilesText
          this.translateService.get('PLEASESELECTFILES').subscribe(value => {
            selectFilesText = value;
          })
          this.imageErorMsg = selectFilesText;
          status = false;
        }
      }
      else {
        status = true;
      }
    }
    else { status = true }
    return status;
  }

  async toastLoading(message: string) {
    let toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: 'bottom'
    });
    return toast.present();
  }

  removeimage(i, j) {
    console.log("Testttt", i, j)
    this.imgarray.splice(i, 1);
    this.uploader.queue.splice(0, 10);
    this.requiredDocFiles = _.filter(this.requiredDocFiles, file => file.removeimage != i.removeimage);
    this.imageErorMsg = "";
    console.log("imgArray", this.imgarray)
  }

  validateFile(files: any) {
    let status: boolean = false;
    if (files.length > 0) {
      files.forEach(element => {
        var ext = element.fileName.substring(element.fileName.lastIndexOf('.') + 1);
        if (ext.toLowerCase() == 'pdf' || ext.toLowerCase() == 'png' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'jpg') {
          status = true;
        }
        else {
          this.imageErorMsg = 'Invalid File Attached';
          status = false;
        }
      });
    }
    else {
      status = true;
    }
    return status;
  }

}
