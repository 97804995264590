import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { CalendarComponentOptions } from 'ion2-calendar';
import { Platform, ModalController, MenuController } from '@ionic/angular';
@Component({
  selector: 'app-calender-model',
  templateUrl: './calender-model.page.html',
  styleUrls: ['./calender-model.page.scss'],
})
export class CalenderModelPage implements OnInit {
  dateRange: { from: string; to: string; };
  type: 'string'; // 'string' | 'js-date' | 'moment' | 'time' | 'object'
  optionsRange: CalendarComponentOptions = {
    pickMode: 'range'
  };
  constructor(
    public modalCtrl: ModalController,
  ) { }

  ngOnInit() {
  }

  async dateRangePass(){
    var data;
   
    if(this.dateRange){
      let from = moment(this.dateRange.from).format('YYYY-MM-DD');
      let to = moment(this.dateRange.to).format('YYYY-MM-DD');
       data = {
        from : from,
        to : to,
      };
    }
   await this.modalCtrl.dismiss(data);
  }

  modelClose(){
    this.modalCtrl.dismiss();
  }


}
