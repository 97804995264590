import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language/language.service';
import { Storage } from '@ionic/storage';
import { modalController } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.page.html',
  styleUrls: ['./change-language.page.scss'],
})
export class ChangeLanguagePage implements OnInit {
  public allLanguages: any;
  alertButton1Offer: any;
  alertButton2Offer: any;
  constructor(private language: LanguageService, private storage: Storage, private translateService: TranslateService,
    private alertCtrl: AlertController, private router: Router, private modalController: ModalController) { }

  ngOnInit() {
    this.getLang();

  }
  //GETTING ALL THE LANGUAGES.
  getLang() {
    this.language.getLanguages().subscribe((response) => {
      this.allLanguages = response;
      console.log(this.allLanguages, "chit");
      this.storage.get('langSelect').then((vala) => {
        this.allLanguages.map(obj => {
          if (obj.name3 == String(vala)) obj.status = true;
          else obj.status = false;
        })
      });
    });
  }
  /*on click of cancel*/
  cancel() {
    this.modalController.dismiss();
      this.router.navigate(['dashboard'])
  }

  async changeLanguage(selected, language) {
    if (language.status) {
      this.storage.set('langSelect', this.allLanguages[selected].name3)
    }
    else {
      this.changelangSetting(selected)
    }

  }

  async changelangSetting(selected) {
    let txt = "";
    //TRANSLATE SERVICE FOR OK BUTTON.
    this.translateService.get('CANCEL_BUTTON').subscribe(
      value => { this.alertButton1Offer = value; });

    //TRANSLATE SERVICE FOR OK BUTTON.
    this.translateService.get('PROCEED').subscribe(
      value => { this.alertButton2Offer = value; });

    this.translateService.get('LANGCHANGEMSG').subscribe(
      value => { txt = value; });

    const alert = await this.alertCtrl.create({
      header: txt,
      buttons: [{
        text: this.alertButton1Offer,
        role: 'cancel',
        handler: () => { }
      },
      {
        text: this.alertButton2Offer,
        handler: () => {
          this.translateService.use(this.allLanguages[selected].name3); //CALLING THE TRANSLATE SERVICE WITH THE HELP OF SELECTED LANGUAGE.
          //SETTING THE STATUS OF SELECTED LANGUAGE TO TRUE.
          this.allLanguages.map((obj, i) => {
            if (i == selected) obj.status = true;
            else obj.status = false;
          });
          this.storage.set('langSelect', this.allLanguages[selected].name3).then(() => {
            console.log(this.allLanguages[selected].name3, 'lang');
            this.router.navigate(['login']);
            this.modalController.dismiss();
          });
        }
      }]
    });
    await alert.present();


  }

}
