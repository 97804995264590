import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class IpGeofetchService {

  constructor(public http2:HttpClient) { }


  getLocWithoutIP(): Observable<any> {
    console.log('helo?')
    return this.http2.get<any>('https://pro.ip-api.com/json/?key=MAkQGoxG3ZmGnth');
  }

  getLocFromIp2(myIP): Observable<any> {
    return this.http2.get<any>('http://api.ipstack.com/' + myIP + '?access_key=ba2bff64aef89e76d105ba5327d7cc60&format=1');
  }

  getLocfromIP(myIP): Observable<any> {
    return this.http2.get<any>('https://kj4v2d1tod.execute-api.us-east-1.amazonaws.com/dev/ip/' + myIP);
  }

  getMyIp() {
    return  this.http2.get<any>('https://pro.ip-api.com/json/?key=MAkQGoxG3ZmGnth').pipe(map(r=>{
      return r.query
     })
     )
  }

  reverseGeo(lat, long) {
    return this.http2.get<any>('https://nominatim.openstreetmap.org/reverse?format=json&lat=' + lat + '&lon=' + long);
  }

}
