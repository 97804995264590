import { Component, OnInit,NgZone  } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { modalController } from '@ionic/core';


@Component({
  selector: 'app-location-modal',
  templateUrl: './location-modal.page.html',
  styleUrls: ['./location-modal.page.scss'],
})
export class LocationModalPage implements OnInit {

  constructor(

    public zone: NgZone,
    private http: HTTP
  ) { }
  input:any;
  autocomplete: { input: string; };
  autocompleteItems: any[];
  location: any;
  placeid:any;
  private myAPI = "AIzaSyB-APjMJyEd4SdIZVAGundpDcLKJD2WpX4";
  AutoCompleteURL:string = "https://maps.googleapis.com/maps/api/place/autocomplete/json?input=";
  PlacesUrl:string = "https://maps.googleapis.com/maps/api/place/details/json?"
  ngOnInit() {
    this.autocompleteItems=[]
    this.input=''
    
  }
  


  updateSearchResults(){
    if (this.input == '') {
      this.autocompleteItems = [];
      return;
    }

  
let headers ="'Content-Type': 'application/json'";
 let urll1 = this.AutoCompleteURL + this.input + "&location=" + 19.0728 + "," + 72.8826 + "&radius=10000&key=" + this.myAPI;
 let urll= encodeURI(urll1)
 this.http.get(urll, {},{headers: headers})
  .then((data) => {
      this.autocompleteItems = [];
      this.zone.run(() => {

        console.log(data)
       
        let x = JSON.parse(data.data)
         x= x['predictions']
        if(x && x.length>0){
      x.map((prediction) => {
        this.autocompleteItems.push(prediction);
      });

  console.log(this.autocompleteItems,'aaaa')
}
       

      });
    });
  }


  selectSearchResult(item) {
    let headers ="'Content-Type': 'application/json'";
    let urll1 = this.PlacesUrl+"placeid="+item.place_id+"&key=" + this.myAPI;
    console.log(urll1)
    let urll= encodeURI(urll1)
    this.http.get(urll, {},{headers: headers}).then((data) => {

      let item = JSON.parse(data.data)
      console.log(item,"x")

      if(item && item.result && item.result.geometry.location  ){
       this.location=item.result.geometry.location;
       modalController.dismiss(item)
      }
    
      console.log('placeidData',data)
      console.log(   this.location,'loc')
      
    })

}

}
