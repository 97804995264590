import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { Events } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(private events : Events, private storage: Storage, private router: Router) { }

   clearData(type?:any) {  
     //langSelect , SELECTED_LANGUAGE , SELECTED_DIR , SelectLang

    this.storage.keys().then(a=>{
      a.map((f,indx)=>{
        if(f != 'langSelect' && f != 'SELECTED_LANGUAGE' && f != 'SELECTED_DIR'  && f != 'SelectLang' && f != 'OnboardingShown')
        {
          this.storage.remove(f)
          if(indx == a.length-1)
          {
            this.router.navigate(['/login']);
            if(type == 'userexpired') {
              this.router.navigate(['/user-expired-display']);
              }
          }
        }
      });
      this.storage.remove('USER_STORE');
      this.storage.remove('memberDetailsForOtp')
      this.storage.remove('tenantDetailsForOtp')
      this.storage.remove('theme_type')
      // this.events.unsubscribe('brandLogo');
    })
}
 

}
