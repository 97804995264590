import { ComponentsModule } from 'src/components/components.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { IonicStorageModule } from '@ionic/storage';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Crop } from '@ionic-native/crop/ngx';
import { Base64 } from '@ionic-native/base64/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
//import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { LazyLoadImageModule , scrollPreset} from 'ng-lazyload-image';
import { InAppReview } from '@ionic-native/in-app-review/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { TranslateLoader, TranslateModule, TranslateService, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NumeralModule } from 'ngx-numeral';
import { httpInterceptorProviders } from 'src/interceptors';

import { AppVersion } from '@ionic-native/app-version/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { CommonModule } from '@angular/common';
//import { OrderByPipe } from './pipes/order-by.pipe';
//import { ReplacePipe } from './pipes/replace.pipe';
import { SmsRetriever } from '@ionic-native/sms-retriever/ngx';

import { HttpService } from './services/http/http.service';
import { AuthGuardService } from './services/authGuard/auth-guard.service';
import { HTTP } from '@ionic-native/http/ngx';


import { EligibleLocationModalPage } from './pages/eligible-location-modal/eligible-location-modal.page';
import { LocationModalPage } from './pages/location-modal/location-modal.page';
import { FreezePage } from './pages/freeze/freeze.page';
import { LocationSearchPage } from './pages/location-search/location-search.page';
import { CountryModalPage } from './pages/country-modal/country-modal.page';
import { OfferTermsModalPage } from './pages/offer-terms-modal/offer-terms-modal.page'
import { WithdrawPage } from './pages/withdraw-modal/withdraw.page';
import { ResumePage } from './pages/resume/resume.page';
import { CancelFreezePage } from './pages/cancel-freeze/cancel-freeze.page';
import { JoinWaitlistPage } from './pages/join-waitlist-modal/join-waitlist.page';
import { FCM } from '@ionic-native/fcm/ngx';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { Network } from '@ionic-native/network/ngx';
import { NetworkErrorService } from './services/network-error/network-error.service';
import {NetworkErrorPage} from './pages/network-error/network-error.page'
import {GifSplashPage} from "./gif-splash/gif-splash.page"
import { CalendarModule } from 'ion2-calendar';
import { PipesModule } from '../pipes/pipes.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppointmentsSelectPurposePage } from './pages/appointments-select-purpose/appointments-select-purpose.page';
import {HotkeyModule, HotkeysService, Hotkey} from 'angular2-hotkeys';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { GateOpenClosePage } from './pages/gate-open-close/gate-open-close.page';
import { Globalization } from '@ionic-native/globalization/ngx';
import {  AppRatingService } from './services/app-rating/app-rating.service';
import { FileUploadModule } from 'ng2-file-upload';
import { Tealium } from '@ionic-native/tealium/ngx';
import { TealiumUtagService } from '../app/services/Tealium/TealiumService';
import { NotificationViewModalPage } from './pages/notification-view-modal/notification-view-modal.page';

import { HTTP_INTERCEPTORS} from '@angular/common/http';


import {HttpInterseptorService} from './services/httpInterseptor/http-interseptor.service';
import { from } from 'rxjs';
import { CalenderModelPage } from './pages/calender-model/calender-model.page';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');

}
@NgModule({
  declarations: [AppComponent,
    GateOpenClosePage,
    NotificationViewModalPage,
    EligibleLocationModalPage,
    LocationModalPage,
    FreezePage,
    ResumePage,
    CancelFreezePage,
    LocationSearchPage,
    CountryModalPage,
    AppointmentsSelectPurposePage,
    OfferTermsModalPage,
    WithdrawPage,
    JoinWaitlistPage,
    NetworkErrorPage,
    GifSplashPage,
    CalenderModelPage],
  entryComponents: [
    GateOpenClosePage,
    NotificationViewModalPage,
    EligibleLocationModalPage,
    LocationModalPage,
    FreezePage,
    ResumePage,
    CancelFreezePage,
    LocationSearchPage,
    CountryModalPage,
    AppointmentsSelectPurposePage,
    OfferTermsModalPage,
    WithdrawPage,
    JoinWaitlistPage,
    NetworkErrorPage,
    GifSplashPage,
    CalenderModelPage
  ],
  imports: [
    FileUploadModule,
    LazyLoadImageModule,
    CalendarModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    HotkeyModule,
    ComponentsModule,
    HttpModule,
    PipesModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(//{
   //   mode: 'md'
   // }
    ) ,
    NumeralModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    OpenNativeSettings,
    HTTP,
    AuthGuardService,
    AppRatingService,
    NetworkErrorService,
    Network,
    HttpService,
    SmsRetriever,
    ScreenOrientation,
    NativeGeocoder,
    Geolocation,
    AppVersion,
    httpInterceptorProviders,
    Device,
    Keyboard,
    StatusBar,
    InAppBrowser,
    InAppReview,
    AndroidPermissions,
    SplashScreen,
    Camera,
    Crop,
    Base64,
    FileChooser,
    FilePath,
    TranslateService,
    FCM,
    FirebaseX,
    Tealium,
    BarcodeScanner,
    Globalization,
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterseptorService, multi: true},
    //  GoogleAnalytics,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

