import { IonSlides, LoadingController, Platform, Events, NavParams, ModalController, IonInput ,MenuController, IonSearchbar} from '@ionic/angular';
import { HttpService } from './../../app/services/http/http.service';
import { ThemeService } from '../../app/services/theme/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter, NgZone, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from '../../app/services/language/language.service';
import { modalController } from '@ionic/core';
import { DataServiceService } from 'src/app/services/data-service/data-service.service';
import { IpGeofetchService } from 'src/app/services/ip-geofetch/ip-geofetch.service';
import { HTTP } from '@ionic-native/http/ngx';
import {Http} from '@angular/http';
import {Unisex} from '../../app/config';
import { CompanyId } from '../../app/config';



@Component({
  selector: 'app-brand-and-center',
  templateUrl: './brand-and-center.component.html',
 // changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./brand-and-center.component.scss'],
})

export class BrandAndCenterComponent implements OnInit {
  @ViewChild("membershipTypeSlide", { static: false }) slides: IonSlides;
  @ViewChild('searchInput', { static: false }) sInput: IonSearchbar;

  brands: any = [];
  tenantList = [];
  filterdTenants = [];
  filterdBrands = [];
  showBrands: boolean;

  emailId: string = "";
  Gender: string;
  DOB: string;
  public myInput = "";
  currentlat:number;
  currentLang:number;
  SearchingTenant;
  input: any;
  autocomplete: { input: string; };
  location: any = {};
  placeid: any;
  searchShow: boolean = false;
  showSearch: boolean = false;
  private myAPI = "AIzaSyB-APjMJyEd4SdIZVAGundpDcLKJD2WpX4";
  AutoCompleteURL: string = "https://maps.googleapis.com/maps/api/place/autocomplete/json?input=";
  AutoCompleteURL1: string = "https://vrn4duc50e.execute-api.ap-south-1.amazonaws.com/prod/GoogleAPIAutocompleteProxy?input=";
  PlacesUrl: string = "https://maps.googleapis.com/maps/api/place/details/json?"
  PlacesUrl1: string = "https://vrn4duc50e.execute-api.ap-south-1.amazonaws.com/prod/GoogleAPIAutocompleteProxy?mode=place&place_id="

  public navParams = new NavParams

  @Output() CenterSelected = new EventEmitter<any>();
  @Output() toggleClick = new EventEmitter<any>();
  SelectedBrand: any;
  SelectedTenant: any;
  listBackup: any[];
  centersList: any[];
  autocompleteItems: any[];
  MaxSignificant: any;
  MinSignificant: any;
  significant: string;
  clubshow: boolean = false;
  homeTenant: Number;
  subscribe: any;
  themeName: string;
  CloseButton: boolean;
  showBrandD;
  homeTenantIdD;
  ismobile: boolean = true;
  showMobilesearch: boolean = false;

  public slideOpts = {
    slidesPerView: 3,
    centeredSlides: true,
    initialSlide: 0,
    breakpoints: {
      767: {
        slidesPerView: 1,
      }
    }
  }
  // @Input() showBranddd;
  // @Input() homeTenantIdd;
  // @Input() selectedTenantt;
  // @Input() genderr;
  // @Input() dateOfBirthh;
  memberDetails: any;
  currentLocation: string;
  display: boolean;




  constructor(private storage: Storage, private platform: Platform,
    public httppp: HTTP,
    private modalController: ModalController,
    public translateService: TranslateService,
    private https: HttpService, private router: Router, public theme: ThemeService,
    public zone: NgZone,
    private menuCtrl: MenuController,
    private dataService: DataServiceService,
    public languageService: LanguageService,
    private loadingController: LoadingController,
    private ipgeo: IpGeofetchService,
    public http :Http
  ) {

  }

  @Input()
  set showBrand(showBrand: boolean) {
    this.showBrands = showBrand;
    

    if (!this.platform.is('mobile')) 
    {
      setTimeout(() => {
        this.sInput.setFocus();
      }, 150);
      this.GetBrandList();
      this.centersList = []
      this.autocompleteItems = []
      this.myInput = "";
    }
    
  }
  get showBrand(): boolean { return this.showBrands!; }

  @Input()
  set selectedTenant(tenant: any) {
    this.SelectedTenant = tenant;

  }
  get selectedTenant(): any { return this.SelectedTenant; }

  @Input()
  set homeTenantId(homeTenantId: Number) {
    this.homeTenant = homeTenantId;

  }


  @Input()
  set gender(gender: any) {
    this.Gender = gender;
    console.log(  this.Gender ,'GenDer')

    if(Unisex){
      this.filterdBrands = this.brands
    }else{
      this.filterdBrands = this.brands.filter(brand => {
        return ((String(brand.Gender).toLowerCase() ==  this.Gender.toLowerCase()))
      })
    }
  
  }

  @Input()
  set dateOfBirth(dateOfBirth: any) {
    this.DOB = dateOfBirth;
    if (    this.Gender  != "" &&     this.Gender  != undefined && !Unisex ) {  
      this.filterdTenants = this.tenantList.filter(center => ((String(center.genderrestriction).toLowerCase() ==  this.Gender.toLowerCase()))
        && center.brandID ==   this.SelectedBrand .BrandID);
    }

    if(Unisex){
      this.filterdTenants =this.tenantList.filter(center => center.brandID ==   this.SelectedBrand .BrandID);
    }

    let age = Math.abs(moment().diff(moment(    this.DOB, "DD-MM-YYYY"), "years"));
    if (age != 0) {
      this.filterdTenants = this.filterdTenants.filter(center => age >= center.TenantMinAge &&
        age <= center.TenantMaxAge && center.brandID ==   this.SelectedBrand .BrandID);
    }
    //


  }

  hometenent(tenant: any) {
    if (this.homeTenant) {
      if (tenant.TenantId == this.homeTenant) return true;
      else return false;
    }
    else return false;
  }


  onInput($event) {

    console.log(`${this.showBrandD} ${this.homeTenantIdD}`)

    if (this.myInput == '' || this.myInput.length == 0) {
      this.autocompleteItems = [];
      this.SearchingTenant = false;
      this.centersList = [];
    } else {
      this.SearchingTenant = true
      this.updateSearchResults()
      this.setFilteredItems(this.myInput)
    }

  }

  onBackPress(event) {
    if (event.keyCode == 8 || event.keyCode == 46) {
      if (this.myInput.length == 0) { this.SearchingTenant = false; }
      this.centersList = this.filterdTenants;
    }
  }


  setFilteredItems(term) {
    this.centersList = this.filterItemsSearch(term);
    console.log(this.centersList, "inside filter")
  }


  updateSearchResults() {
    console.log("GSearch",this.autocompleteItems)
   
      if (!this.platform.is('cordova'))
      {
        let urll1 = this.AutoCompleteURL1 + this.myInput + "&lat=" + this.currentlat + "&long=" + this.currentLang + "&mode=autocomplete";
        let urll = encodeURI(urll1)

        this.http.get(urll).subscribe(data=>{
          this.autocompleteItems = [];
          console.log("GSearch",this.autocompleteItems)
          this.zone.run(() => {
            let x = JSON.parse(data['_body'])
            x = x['predictions']
            if (x && x.length > 0) {
              x.map((prediction) => {
                this.autocompleteItems.push(prediction);
              });
            }
          });
        })
      }

      else
      {
        let headers = { 'Content-Type': 'application/json' }
        let urll1 = this.AutoCompleteURL + this.myInput + "&location=" + this.currentlat + "," + this.currentLang + "&radius=10000&key=" + this.myAPI;
        let urll = encodeURI(urll1)

        this.httppp.get(urll,{},headers)
        .then((data) => {
          this.autocompleteItems = [];
          console.log("GSearch",this.autocompleteItems)
          this.zone.run(() => {
            let x = JSON.parse(data['data'])
            x = x['predictions']
            if (x && x.length > 0) {
              x.map((prediction) => {
                this.autocompleteItems.push(prediction);
              });
            }
          });
        });
      }

  }


  selectSearchResult(item) {
    this.myInput = ""
    if (!this.platform.is('cordova'))
    {
      let urll1 = this.PlacesUrl1+item.place_id;
      console.log(urll1)
      let urll = encodeURI(urll1)
      this.http.get(urll).subscribe((data) => {
        let item = JSON.parse(data['_body'])
        if (item && item.result && item.result.geometry.location) {
          this.location = item.result.geometry.location;
          this.autocompleteItems = []
          this.SearchingTenant = false;
          this.sortByLoc()
        }
      })
    }
    else
    {
      let headers = { 'Content-Type': 'application/json' }
      let urll1 = this.PlacesUrl + "placeid=" + item.place_id + "&key=" + this.myAPI;
      console.log(urll1)
      let urll = encodeURI(urll1)
      this.httppp.get(urll,{},headers).then((data) => {
        let item = JSON.parse(data['data'])
        if (item && item.result && item.result.geometry.location) {
          this.location = item.result.geometry.location;
          this.autocompleteItems = []
          this.SearchingTenant = false;
          this.sortByLoc()
        }
      })
    }
  }


  //MODIFIES THE LIST FROM THE SEARCH TEXT.
  filterItemsSearch(searchText: string) {
    console.log("genderrrrrrrrr",this.Gender)
    let dobnow;
    let age = 0;
    if(this.slides){
      if (this.DOB) {
        dobnow = moment(this.DOB);
        if(!dobnow.isValid())
        {
          dobnow = moment(this.DOB,"DD-MM-YYYY");
        }
        age = Math.abs(moment().diff(moment(dobnow, "DD-MM-YYYY"), "years"))
      }
      let _gender: string;
      if (this.Gender) {
        _gender = this.Gender.toLowerCase() == "m" ? "MALE" : "FEMALE";
      }
      let filterdTenants
      if (_gender != "" && _gender != undefined && !Unisex) {
        filterdTenants = this.tenantList.filter(center => ((String(center.genderrestriction).toLowerCase() == _gender.toLowerCase())));
      }
      else
      {
        filterdTenants= this.tenantList  // filter searchterm irrespective of gender when gender is not specified
      }
      if (age != 0) {
        filterdTenants = filterdTenants.filter(center => age >= center.TenantMinAge &&
          age <= center.TenantMaxAge );
      }
  
      if (!this.centersList) return [];
      if (!searchText) return this.tenantList;
      searchText = searchText.toLowerCase();
      console.log(searchText, "search")
      return filterdTenants.filter(it => {
  
        return it.TenantName.toLowerCase().includes(searchText);
      });

    } else if(this.brands.length ==1){   // when we have only brand for a company id

      if (this.DOB) {
        dobnow = moment(this.DOB);
        if(!dobnow.isValid())
        {
          dobnow = moment(this.DOB,"DD-MM-YYYY");
        }
        age = Math.abs(moment().diff(moment(dobnow, "DD-MM-YYYY"), "years"))
      }
      let _gender: string;
      if (this.Gender) {
        _gender = this.Gender.toLowerCase() == "m" ? "MALE" : "FEMALE";
      }
      let filterdTenants
      if (_gender != "" && _gender != undefined && !Unisex) {
        filterdTenants = this.filterdTenants.filter(center => ((String(center.genderrestriction).toLowerCase() == _gender.toLowerCase())));
      }
      else
      {
        filterdTenants= this.filterdTenants  // filter searchterm irrespective of gender when gender is not specified
      }
      if (age != 0) {
        filterdTenants = filterdTenants.filter(center => age >= center.TenantMinAge &&
          age <= center.TenantMaxAge );
      }
  
      if (!this.centersList) return [];
      if (!searchText) return this.filterdTenants;
      searchText = searchText.toLowerCase();
      console.log(searchText, "search")
      return filterdTenants.filter(it => {
  
        return it.TenantName.toLowerCase().includes(searchText);
      });

    }

  }

  close() {
    if (this.ismobile) {
      if (this.router.url == '/club-locator/69') {
        this.router.navigate(['./dashboard']);
      }
      {
        this.modalController.dismiss()
      }
    }
    else {
      console.log(this.router.url, 'uriiiii')
      if (this.router.url == '/club-locator/69') {
        this.router.navigate(['./dashboard']);
      }
      else {
        this.showBrands = false; this.toggleClick.emit(false);
      }
    }

  }

  toggelmenu(){
    this.modalController.dismiss();
    this.router.navigate(['/dashboard']);
    this.menuCtrl.toggle();
}





  ngOnInit() {
    if (this.router.url == '/club-locator/69') {
      this.ipgeo.getLocWithoutIP().subscribe(data => {
        //console.log(data,'ipdatttt');
       this.currentLocation = data.city+","+data.country;
      });
      this.clubshow = true;
      }

  //  this.subscribe = this.platform.backButton.subscribe(() => {
  //    if (this.router.url == '/buy-membership/type') {
  //      this.showBrands = false; this.toggleClick.emit(false);
  //    }
  //  });

    // setTimeout(() => {
    //   this.sInput.setFocus();
    // }, 100);
    this.storage.get('USER_STORE').then(obj => {
      this.memberDetails = obj;
    });
    this.GetBrandList();
    if (this.platform.is('desktop') || this.platform.is('pwa')) {
      console.log('platform is web');
      this.ismobile = false;
    }
    else {
      console.log('platform is mobile');
    }
    this.CloseButton = true;
    if (this.router.url.includes('/club-locator')) {
      this.CloseButton = false;
    }
    this.autocompleteItems = [];
    this.SearchingTenant = false;
    this.centersList = [];
    setTimeout(() => {
      this.theme.storedThemetype.then(theme => {
        this.themeName = theme
      });
    }, 2000)

  }

  getLocation()
  {
    console.log('get current location');
    if(!this.platform.is("cordova"))
    {
      this.ipgeo.getLocWithoutIP().subscribe(data => {
        console.log(data,'ip dattta');
        //this.myInput=data.city+","+data.country;
        //this.currentLocation = data.city+","+data.country;
        this.currentlat=data.lat;
        this.currentLang=data.lon;
   
        if (data) {
         this.location['lng'] = data.lon
         this.location['lat'] = data.lat
         this.autocompleteItems = []
         this.SearchingTenant = false;
         this.sortByLoc()
       }
        
        //this.showSearch=true;
   
       });
    }
    else
    {
        this.myLoc();
    }
 

  }

  closeSearch() {
    this.showSearch = false;
    this.showMobilesearch = false;
  }

  openSearch() {
    this.showSearch = true;
    this.showMobilesearch = true;
    setTimeout(() => {
      this.sInput.setFocus();
    }, 150);
  }

  onCancel() {
    this.showSearch = false;
    this.showMobilesearch = false;
  }

  async GetBrandList() {

    this.storage.get('langSelect').then((val) => {
      let companyID = CompanyId;
      this.https.GetBrands(companyID, val).subscribe((response) => {
        console.log(response, "response center selection");
        let data = response.data;
        this.brands = data;
        let _gender;
        if (this.Gender) {
          _gender = this.Gender.toLowerCase() == "m" ? "M" : "F";
        }
        if (_gender && !Unisex) {
          this.filterdBrands = this.brands.filter(brand => {
            return ((String(brand.Gender).toLowerCase() == _gender.toLowerCase()))
          })
        }
        else {
          this.filterdBrands = this.brands;
        }
        if (this.SelectedTenant && this.filterdBrands) {
          if(this.slides){
            this.slides.slideTo(this.filterdBrands.findIndex(x => x.BrandID == this.SelectedTenant.brandID), 0);
          }
        }
        this.GetTenantList();
      }
      )
    });
  }

  async GetTenantList() {
    this.storage.get('langSelect').then((val) => {
      let companyID = CompanyId;
      this.https.withoutTokeninterCenterList(companyID, val, {}).subscribe((response) => {
        this.tenantList = response.data['Tenant'];

        this.tenantList.forEach((a,i)=>{
          this.tenantList[i]['distt'] =  this.distBetweenPlaces(Number(a.TenantAddress[0]['Lat']),Number(a.TenantAddress[0]['Long'])  ,   Number(this.location['lat'])   ,   Number(this.location['lng'])   ) 
        })


        console.log('hi1')
        if(this.slides){
       
        this.slides.getActiveIndex().then(index => {
          this.filterdTenants = this.tenantList;
          this.listBackup = this.tenantList;
          let brand = this.filterdBrands[index];
          this.SelectedBrand = this.filterdBrands[index];
          if (brand && this.filterdTenants) {
            this.filterdTenants = this.tenantList.filter(tenant => tenant.brandID == brand.BrandID);
          }
          let dobnow;
          let age = 0;
          if (this.DOB) {
            dobnow = moment(this.DOB);
            if(!dobnow.isValid())
            {
              dobnow = moment(this.DOB,"DD-MM-YYYY");
            }


            age = Math.abs(moment().diff(moment(dobnow, "DD-MM-YYYY"), "years"))
          }
          let _gender: string;
          if (this.Gender) {
            _gender = this.Gender.toLowerCase() == "m" ? "MALE" : "FEMALE";
          }
          if (_gender != "" && _gender != undefined && !Unisex) {
            this.filterdTenants = this.tenantList.filter(center => ((String(center.genderrestriction).toLowerCase() == _gender.toLowerCase()))
              && center.brandID == brand.BrandID);
          }

          if(Unisex){
            this.filterdTenants = this.tenantList.filter(center => (center.brandID == brand.BrandID));
          }
          if (age != 0) {
            this.filterdTenants = this.filterdTenants.filter(center => age >= center.TenantMinAge &&
              age <= center.TenantMaxAge && center.brandID == brand.BrandID);
          }
          // if (dobnow != undefined && dobnow != "" && dobnow.length > 10) {
          //   dobnow = dobnow.substr(0, 10)
          // }
          // if ((dobnow != undefined && dobnow != "") && this.filterdTenants) {
          //   this.filterdTenants = this.filterdTenants.filter(center => age >= center.TenantMinAge &&
          //     age <= center.TenantMaxAge && center.brandID == brand.BrandID);
          // }
          this.getLocation();
          

        });
      }else if(this.brands.length ==1){    // when we have only brand for a company id
        this.filterdTenants = this.tenantList.filter(tenant => tenant.brandID == this.brands[0].BrandID);
        let dobnow;
        let age = 0;
        if (this.DOB) {
          dobnow = moment(this.DOB);
          if(!dobnow.isValid())
          {
            dobnow = moment(this.DOB,"DD-MM-YYYY");
          }


          age = Math.abs(moment().diff(moment(dobnow, "DD-MM-YYYY"), "years"))
        }
        let _gender: string;
        if (this.Gender) {
          _gender = this.Gender.toLowerCase() == "m" ? "MALE" : "FEMALE";
        }
        if (_gender != "" && _gender != undefined && !Unisex) {
          this.filterdTenants = this.tenantList.filter(center => ((String(center.genderrestriction).toLowerCase() == _gender.toLowerCase()))
            && center.brandID == this.brands[0].BrandID);
        }

        if(Unisex){
          this.filterdTenants = this.tenantList.filter(center => (center.brandID == this.brands[0].BrandID));
        }
        if (age != 0) {
          this.filterdTenants = this.filterdTenants.filter(center => age >= center.TenantMinAge &&
            age <= center.TenantMaxAge && center.brandID == this.brands[0].BrandID);
        }
        this.getLocation();

      }
      }
      )
    });
  }


  DOBChange() {
    if (this.DOB != undefined && this.DOB != "") {
      let dob = this.DOB;
      let age = this.AgeCalc(moment(dob));
      this.filterdTenants = this.filterdTenants.filter(center => age >= center.TenantMinAge &&
        age <= center.TenantMaxAge);
    }
    console.log(this.filterdTenants, 'CDOb')
  }

  ChangeSlide(i){


    console.log(i,'indextoMove')
    if(this.slides){
      this.slides.slideTo(i)
    }
  }


  brandSlideChange($event, selection, indexofBrand) {
    console.log("Slidddingg", selection, indexofBrand)
    if(this.slides){
    
    this.slides.getActiveIndex().then(index => {
      if (selection) {
        index = indexofBrand;
      }
      this.SelectedBrand = this.filterdBrands[index]
      let brand = this.filterdBrands[index];
      this.filterdTenants = this.tenantList.filter(center => center.brandID == brand.BrandID);
      let dobnow;
      let age = 0;
      if (this.DOB) {
        dobnow = moment(this.DOB);
        if(!dobnow.isValid())
        {
          dobnow = moment(this.DOB,"DD-MM-YYYY");
        }
        age = Math.abs(moment().diff(moment(dobnow, "DD-MM-YYYY"), "years"))
      }
      let _gender: string;
      if (this.Gender) {
        _gender = this.Gender.toLowerCase() == "m" ? "MALE" : "FEMALE";
      }
      if (_gender != "" && _gender != undefined && !Unisex) {
        this.filterdTenants = this.tenantList.filter(center => ((String(center.genderrestriction).toLowerCase() == _gender.toLowerCase()))
          && center.brandID == brand.BrandID);
      }
      if(Unisex){
        this.filterdTenants = this.tenantList.filter(center => center.brandID == brand.BrandID);
      }
      if (age != 0) {
        this.filterdTenants = this.filterdTenants.filter(center => age >= center.TenantMinAge &&
          age <= center.TenantMaxAge && center.brandID == brand.BrandID);
      }

      this.sortByLoc()
    });
  }
  }

  AgeCalc(date) {
    var d = date
    let ageDifMs = Date.now() - d.getTime();
    let ageDate = new Date(ageDifMs); // miliseconds from epoch
    let res = Math.abs(ageDate.getUTCFullYear() - 1970);
    return res;
  }

  SelectTenant(tenant) {
    this.myInput = ""
    this.SelectedTenant = tenant;
    this.SelectedBrand = this.filterdBrands.find(b => b.BrandID == this.SelectedTenant.brandID);
    let currency = {
      'currencyFormat': this.SelectedTenant.Currency
    }
    this.storage.set('appCurrency', currency).then(val =>{
      let SlectedData = {
        Brand: this.SelectedBrand,
        Tenant: this.SelectedTenant
      }
      this.CenterSelected.emit(SlectedData);
      this.showBrands = false;
      this.toggleClick.emit(false);
      modalController.dismiss(SlectedData);
      
      if (this.router.url.includes('/club-locator')) {
        this.showBrands = true;
        this.TenantDetails(this.SelectedTenant.TenantId);
      } else {
        this.showBrands = false;
      }
      this.toggleClick.emit(false);
    })
  }


  TenantDetails(ID) {
    console.log(ID,'tenant id')
    let Details = {
      "TenantId": ID,
      'lang_isocode': 'en-US'
    }
    this.https.withoutTokeninterCenterID(Details, {}).subscribe(response => {
    
      this.storage.set("currmultiplier", response.data.club.currency_multiplier);
      if (response.data.club.posdecimal) {
        this.MaxSignificant = response.data.club.posdecimal;
        this.MinSignificant = response.data.club.posdecimal;
        this.significant = this.MaxSignificant + '.' + this.MinSignificant + '-' + 2;
        let posdeci = {
          'mindecimals': response.data.club.posdecimal,
          'maxDecimals': response.data.club.posdecimal,
        }
        this.storage.set("posdecimal", posdeci);
      }
      else {
        this.MinSignificant = 2;
        this.MaxSignificant = 1;
        this.significant = this.MaxSignificant + '.' + this.MinSignificant + '-' + 2;
        let posdeci = {
          'mindecimals': 2,
          'maxDecimals': 1,
        }
        this.storage.set("posdecimal", posdeci);
      }
      if(response.data.club.taxType)
        {
          this.storage.set("taxType",response.data.club.taxType)
        }
    });
  }

  onClickButton(event) {
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Please Wait..',
      duration: 5000
    });
    await loading.present().then(() => console.log('present loading'));
  }

  checkFocus() {
    this.searchShow = true;
    this.showMobilesearch = true;
  }

  checkBlur() {
    this.searchShow = false;
    this.showSearch = false;
    this.showMobilesearch = false;
  }

  goToClubDetails(item) {
    let dis = {
      lat: item.TenantAddress[0].Lat,
      long: item.TenantAddress[0].Long
    }
    const navigateData = {
      from: 'dashboard',
      memberDetails: this.memberDetails,
      tenantDetails: item,
      distance: dis,
      tenantContact: item.tenantContact,
      tenantEmail: item.tenantEmail,
      logo: ''
    }
    this.dataService.setData('memberDetails', navigateData);
    this.router.navigateByUrl('/club-details/memberDetails');

  }

  sortByLoc()
  {
    this.tenantList.forEach((a,i)=>{
      this.tenantList[i]['distt'] =  this.distBetweenPlaces(Number(a.TenantAddress[0]['Lat']),Number(a.TenantAddress[0]['Long'])  ,   Number(this.location['lat'])   ,   Number(this.location['lng'])   ) 
    })

    this.filterdTenants =  this.filterdTenants.sort((a,b)=> Number(a['distt']) - Number(b['distt']) )
  }


  distBetweenPlaces(lat1, long1, lat2, long2) {
    let radlat1 = Math.PI * lat1 / 180;
    let radlat2 = Math.PI * lat2 / 180;
    let radlon1 = Math.PI * long1 / 180;
    let radlon2 = Math.PI * long2 / 180;
    let theta = long1 - long2;
    let radtheta = Math.PI * theta / 180
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515 * 1.609344;
    return Math.round(dist * 100) / 100;
  }

  myLoc()
  {
    this.storage.get('Location').then(locc=>{
      if(locc)
      {
        this.location['lng'] = locc['longitude']
        this.location['lat'] = locc['latitude']
        this.sortByLoc();
      }
      else
      {
        this.ipgeo.getLocWithoutIP().subscribe(data => {
          console.log(data,'ip dattta');
          this.currentlat=data.lat;
          this.currentLang=data.lon;
     
          if (data) {
           this.location['lng'] = data.lon
           this.location['lat'] = data.lat
           this.autocompleteItems = []
           this.SearchingTenant = false;
           this.sortByLoc()
         }
          

        })
      }
    });
  }


}
