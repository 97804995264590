import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, pipe,throwError } from 'rxjs';
import { tap,catchError } from 'rxjs/operators';
import { IdentityService } from '../identity/identity.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterseptorService  implements HttpInterceptor {

  constructor(    public idp: IdentityService    ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):   Observable<HttpEvent<any>> {


    console.log('interceptor')

    // We retrieve the token, if any
    const token = ''
    let newHeaders = req.headers;
    if (token) {
       // If we have a token, we append it to our new headers
       newHeaders = newHeaders.append('authtoken', token);
    }
    // Finally we have to clone our request with our new headers
    // This is required because HttpRequests are immutable
   // const authReq = req.clone({headers: newHeaders});
    // Then we return an Observable that will run the request
    // or pass it to the next interceptor if any
    return next.handle(req).pipe(

      catchError( response => {
        console.log(response,'err')
         this.idp.refreshtoken();
        return throwError(response);

      })
    )

    // All HTTP requests are going to go through this method
}
}
