import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpService } from 'src/app/services/http/http.service';
import { AlertController } from '@ionic/angular';
import { DataServiceService } from 'src/app/services/data-service/data-service.service';
import { Router } from '@angular/router';
import { modalController } from '@ionic/core';
import { LanguageService } from '../../services/language/language.service';
import { ThemeService } from '../../services/theme/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { CompanyId } from '../../config'
@Component({
  selector: 'app-appointments-select-purpose',
  templateUrl: './appointments-select-purpose.page.html',
  styleUrls: ['./appointments-select-purpose.page.scss'],
})
export class AppointmentsSelectPurposePage implements OnInit {
  tabIndex: number = 0;
  ISOCODE: any;
  purposeList: any;
  MembershipArray: any;
  BookedPurposes: any;
  Bookedarray: any = [];
  TrainersArray: any;
  ShowpurposeList: any;
  themeName: any;
  dataAvailable: boolean;
  homeCenterId: any;

  constructor(public storage : Storage,
    private HTTPS: HttpService, 
    public alertCtrl : AlertController,
    private dataService: DataServiceService,
    public router : Router,
    public theme: ThemeService,
    public translateService : TranslateService,
    public languageService : LanguageService) {
   }

   
  ngOnInit() {

    this.theme.storedThemetype.then(theme => {
      this.themeName = theme
    });
    
    this.storage.get("langSelect").then((val) => {
      this.storage.get('USER_STORE').then (user => {
        this.homeCenterId = user.tenantID;
      this.storage.get('tocken').then((val1) => {
        let tocken = val1;
        let IsoCode = val;
        console.log("Token is :",tocken)
        this.initPuposeSheet(tocken, IsoCode)
      })
    })
  })
    
  }

  tabIng(index) {
    this.tabIndex = index;
  }

  async initPuposeSheet(tocken, IsoCode) {
    // this.presentLoading();
    let ALL = {}
    let Finalobj = []

    let purpose: any = []
    let p = await this.getPurpose(tocken, IsoCode);
    p = p['data'].filter(data=>(data.purposeType.toLowerCase() == "appointment"))
    //console.log(p, 'purposeeee')
    this.purposeList = await this.getPurpose(tocken, IsoCode);
    this.purposeList = this.purposeList['data']
    this.ShowpurposeList=this.purposeList['data']

    let m = this.MembershipArray || await this.getMemberships(tocken, IsoCode)
   
    
    if(m['data']['error'])
    {
      m = [];
    }
    else
    {
      m = m['data']
    }
    
    console.log("343gfdf0",m)
        this.dataAvailable = true;

    // console.log(m, 'memberships')
    let membership = []
    if (m['membershipDetails']) {
      membership = m['membershipDetails'].filter(data => (data.Status.toLowerCase() == "active"));
      
    }
    console.log(p, membership, ';ashfjhf');
    membership.map(memdata => {
      memdata["sessionDurationPaid"] = memdata["sessionDuration"] 
    })
    purpose = p
    purpose.map(data => {
      data['book'] = false;
      // For Booked
      membership.map(datamembership => {
        if (datamembership.PurposeList.includes(data['purposeName'])) {
          data['centerAccess'].map(center => {

            if (center['centerId'] == datamembership.tenantaccess) {
              let ALLobj = { ...datamembership, ...data, ...center }
              Finalobj.push(ALLobj)
            }
          })


        }
      })

      if (data['membershipTypes'].length == 0) {
        membership.map(datamembership => {
          data['centerAccess'].map(center => {

            if (center['centerId'] == datamembership.tenantaccess) {
              //console.log(datamembership, data, center, 'free');
              let count = 0;
              for (let i = 0; i < center['baseMembershipTypes'].length; i++) {
                if (center['baseMembershipTypes'][i] == "") {
                  count += 1;
              
                }
              // let B = new Set(center['baseMembershipTypes'])
              // //console.log(B)
              // if (String(B).length == 0) {
                
              if(count == 3){
                data['book'] = true;
            
                //console.log(datamembership, data, center, 'free');
                let ALLobj = { ...datamembership, ...data, ...center }
                Finalobj.push(ALLobj)
              }
        
              }
              // console.log(datamembership.ProductTypeID, Array.from(B), 'arrra')

            }

          })
        })
      }

    });
    let k = []
    //  ["337", "", ""]0: "337"1: ""2: ""length: 3__proto__: Array(0) 337 "pfree"
    console.log(JSON.stringify(membership),"Basse0")
    let base = membership.filter(dataMem => dataMem.IsBasemembership == true)
    console.log(base,"Basse")
    if (base.length > 0) {
      base.map(data_base => {

        let pFree = purpose.filter(data => data['membershipTypes'].length == 0)

        pFree.map((pdata, i) => {

          pdata['centerAccess'].map(center => {

            console.log(data_base,"222")
            if((data_base['tenantaccess'].split(",")).length > 0)
            {
                if((data_base['tenantaccess'].split(",")).includes(String(center['centerId'])))
                {
                  if (center['baseMembershipTypes'].includes(String(data_base.ProductTypeID))) {
                    let ALLobj = { ...data_base, ...pdata, ...center }
                    Finalobj.push(ALLobj)
                  }
                }
            }

           //  console.log(Finalobj, 'JHCVHKISBVFSHIFBVSIFHISFBV');
          })
        });




        Finalobj.map((ALL, i) => {
          let count = 0;
          for (let i = 0; i < ALL['baseMembershipTypes'].length; i++) {
            if (ALL['baseMembershipTypes'][i] == "") {
              count += 1
            }
          }
          if (ALL['baseMembershipTypes'].includes(String(data_base.ProductTypeID)) || count == 3) {
            Finalobj[i]['book'] = true
            k.push(Finalobj[i])
          }


        });

        this.BookedPurposes = k

        // waiting for purposetype key to come
        // this.BookedPurposes = this.BookedPurposes.filter(res=>{
        //   res.purposeType.toLowerCase == "appointment"
        // })
        this.BookedPurposes.map(data => {
          this.Bookedarray.push(data.purposeId);
        })



      })
    }
    else
    {

      let noBase = membership.filter(dataMem => dataMem.IsBasemembership == false)
      let pFree = purpose.filter(data => data['membershipTypes'].length == 0)

      console.log("33434g",pFree)
      let memFree = []
      noBase.map(nnb=>{
        pFree.map((pdata, i) => {

          pdata['centerAccess'].map(center => {
            if (center['baseMembershipTypes'].includes(String(nnb.ProductTypeID))) {
              let ALLobj = { ...nnb, ...pdata, ...center }
              memFree.push(ALLobj)
            }
             console.log(center['baseMembershipTypes'], 'JHCVHKISdBVFSHIFBVSIFHISFBV');
          })
        });
      })

      this.BookedPurposes = memFree
      this.BookedPurposes.map(data => {
        this.Bookedarray.push(data.purposeId);
      })






// FREE purpose no mem req
      let allFree = []
      this.storage.get('USER_STORE').then(US => {
        US['tenantID'] 
        let cnter;
        pFree.map(p=>{
          let allempt = true;
          p['centerAccess'].map(ca=>{
            if(ca['centerId'] == US['tenantID'] )
            {
              cnter = ca
              ca['baseMembershipTypes'].map(cm=>{
                if(cm.length > 0)
                {
                  allempt = false;
                }
                
              })
             
            }
          })
          if(allempt)
          {
            let allObj = { ...p , ...cnter }
            allFree.push(allObj);
            this.BookedPurposes = allFree
            this.BookedPurposes.map(data => {
              this.Bookedarray.push(data.purposeId);
            })
           // this.Bookedarray.push(p.purposeId);


          }
        })
        this.FilterList("appointment");

        
      })





    }
    

      //Enquiry
      if(purpose.filter(data => data['membershipTypes'].length == 0) && m<=0)
      {
        let pFree = purpose.filter(data => data['membershipTypes'].length == 0);
        let FinalObjFree=[];
        console.log("pFreepFree",pFree)
        this.storage.get('USER_STORE').then(US => {
          US['tenantID'] 
          let cnter;
          pFree.map(p=>{
            let allempt = true;
            p['centerAccess'].map(ca=>{
              if(ca['centerId'] == US['tenantID'] )
              {
                cnter = ca
                ca['baseMembershipTypes'].map(cm=>{
                  if(cm.length > 0)
                  {
                    allempt = false;
                  }
                  
                })
               
              }
            })
            if(allempt)
            {
              let allObj = { ...p , ...cnter }
              FinalObjFree.push(allObj);
              this.BookedPurposes = FinalObjFree
              this.BookedPurposes.map(data => {
                this.Bookedarray.push(data.purposeId);
              })
             // this.Bookedarray.push(p.purposeId);


            }
          })
          this.FilterList("appointment");

          
        })

      }


      this.forSessionCount();
 

    
    // console.log(this.BookedPurposes, "BOOKEDPURPOSE");
    // console.log(this.purposeList, "BOOKEDPURPOSE");
    // console.log(this.Bookedarray,"BOOKEDARRAY")

  }

  getPurpose(tocken, IsoCode): Promise<any> {
    return this.HTTPS.PurposesGet(tocken, IsoCode, CompanyId).toPromise()
  }

  getMemberships(tocken, IsoCode) {
    return this.HTTPS.profileGetMembershipDetails(tocken, {}, IsoCode || 'en-US').toPromise();
  }

  forSessionCount() {
    this.FilterList("appointment");
    this.BookedPurposes.map(bID => {
      this.purposeList.map(Plist => {
        if (bID.purposeId == Plist.purposeId) {
          Plist["totalSessions"] = bID["totalSessions"];
          Plist["usedSessions"] = bID["usedSessions"]; 
           if(bID.membershipTypes.length > 0 ){
          Plist["sessionDuration"] = bID["sessionDurationPaid"]; 
           }

        }
      })
    })
  }






  FilterList(type) {
    console.log(type,"yoooooo")
    this.ShowpurposeList=this.purposeList.filter(res =>( res.purposeType.toLowerCase() == type));
    console.log(this.ShowpurposeList,"oihuuhouhoihu")
 }






  PuposeSelect(purposeID,Type ) {
    console.log(purposeID,Type)
   if( Type.toLowerCase() == "appointment"){
    console.log(this.BookedPurposes, "this.BookedPurposes")
    console.log(purposeID)
    let purpose = this.BookedPurposes.filter(data => {
      return (data.purposeId == purposeID && data.sessiontype.toLowerCase() == 'sessions')
    });
    // let totalsessions = purpose[0].totalSessions;
    // let usedsessions = purpose[0].usedSessions
    // console.log(purpose[0].pendingSessions,"pending")
    if (purpose[0].pendingSessions <= 0 && purpose[0].membershipTypes.length > 0) {
      this.SessionValidity()
    }
    else {
      if (purpose[0].TrainerID) {
        if(purpose[0].TrainerID.length)
        {
          this.storage.get('tocken').then((val) => {
            let tocken = val;
            // let tocken="Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjU5QTJDNTlFQTc2REM2NzZDQUEwRTExMzFGOEIwRDgxOEYyMEY5NTYiLCJ0eXAiOiJKV1QiLCJ4NXQiOiJXYUxGbnFkdHhuYktvT0VUSDRzTmdZOGctVlkifQ.eyJuYmYiOjE1Njg1MzU1MTcsImV4cCI6MTU3MTEyNzUxNywiaXNzIjoiaHR0cHM6Ly9pZHNydmNsb25lLmZpdG5lc3Nmb3JjZS5jb20iLCJhdWQiOlsiaHR0cHM6Ly9pZHNydmNsb25lLmZpdG5lc3Nmb3JjZS5jb20vcmVzb3VyY2VzIiwiRkZXZWJBUElWMiJdLCJjbGllbnRfaWQiOiJCMkMiLCJzdWIiOiIxNTIwNDQ1NzIzfjk5OX4yMTQyfmVuLVVTIiwiYXV0aF90aW1lIjoxNTY4NDQzNTY0LCJpZHAiOiJsb2NhbCIsImNvbXBhbnlpZCI6Ijk5OSIsImNlbnRlcmlkIjoiMjE0MiIsIm1lbWJlcmlkIjoiMTUyMDQ0NTcyMyIsIm1lbWJlcm5hbWUiOiJzb25hbCBzaW5naCIsIm1lbWJlcmVtYWlsIjoicHJhbmFsaWNAZml0bmVzc2ZvcmNlLmNvbSIsImVucXVpcnljZW50ZXIiOiIyMDAzIiwiaXNvY29kZSI6ImVuLVVTIiwicHJlZml4IjoiTkxGTCIsInRpbWV6b25lIjoiQXJhYiBTdGFuZGFyZCBUaW1lIiwiY3VycmVuY3kiOiJTQVIiLCJhZG1pbnVzZXJpZCI6IjEiLCJhZG1pbnVzZXJuYW1lIjoiYWRtaW4iLCJzdWJkb21haW4iOiJsZWVqYW1zdGFnaW5nLmZpdG5lc3Nmb3JjZS5jb20iLCJtb2JpbGVubyI6IjExMTIyMjk5OSIsImNvdW50cnljb2RlIjoiOTY2Iiwic2NvcGUiOlsib3BlbmlkIiwiRkZXZWJBUElWMiIsIm9mZmxpbmVfYWNjZXNzIl0sImFtciI6WyJwd2QiXX0.G5tdxvScPoSv-Vu2KfokQfB4J5s4Le6KRFpcgY4IuDr4Lwbwi3f4WNdCEEpJnYW3l8CBVBW44P4K459ZCMchkL2XoH2La3Jv4FEMMRs8ROdFpxlS6tBt41PDt_THYzBnURQXVW08FjQsubIQMtiOh636l6VX7zzCwWeAfVEQfg-CGba0sf9xBnO_J6j-nCmLvn0JB67EWMc3VwtLTURbVX0zHc3rhB0_p6E6Fl2uallvvl4Z54BwXNHXOCq7WEt5OHAedUMSMV6jfqGOgYHKu7CVAZvo4jI3Y8Qq7KFySY3b2cwCpA4RbuXv_zkr4cUNn6ivH3Ek7OSYq5qS5lOzDA"
            this.HTTPS.TrainerDetailGetDetail(purpose[0].TrainerID, tocken, "TrainerDetailsByIdCache").subscribe(response => {

             this.TrainersArray = response;
         
          // let Trainer = this.TrainersArray.filter(data => {
          //   data.TrainerID == purpose[0].TrainerID
          // });
          // console.log(Trainer,"LEH TRAINER")
          if (this.TrainersArray) {
            let trainerDetails = {
              "Trainername":this.TrainersArray.data.trainerName,
              "TrainerPic": this.TrainersArray.data.trainerPic,
               "trainerrating":this.TrainersArray.data.trainerRating,
             "trainerratingcount":this.TrainersArray.data.trainerRatingCount,
             "TrainerID": purpose[0].TrainerID
            }
            const navigateData = {
              'purpose': purpose[0],
              'trainer': trainerDetails,
              'Sessionowner' : true
            };
           
            this.dataService.setData('memberDetails', navigateData);
            this.router.navigateByUrl('/date-time/memberDetails');
  
          } else {
            this.GotoTrainerSelect(purpose)
          }
        })
      })
        }

      } else {
        this.GotoTrainerSelect(purpose)
      }
      
    }
    this.closeModal();
    

  }else{
    this.gotoClasses()
  }

  }

  async SessionValidity() {    
    let sessionLimitText
    this.translateService.get('SESSIONREQREACHED').subscribe(value => {
      sessionLimitText = value;
    })

    let okText
    this.translateService.get('OK').subscribe(value => {
      okText = value;
    })

  const alert1 = await this.alertCtrl.create({
    header: sessionLimitText,
    buttons: [
    {
      text: okText,
      handler: () => {

      }
    }]
  });
  alert1.present();
  }

  GotoTrainerSelect(p) { 

    console.log(p, 'ppp')
    const navigateData = {
      'purpose': p,
    }
    this.dataService.setData('memberDetails', navigateData);
    this.router.navigateByUrl('/specific-trainer/memberDetails');
  }

  closeModal(){
    modalController.dismiss();
  }

  gotoClasses(){
    this.router.navigateByUrl('/classes')
    this.closeModal();
  }

  Classes(type){
    console.log(type,"YOOOOO")
    if(type == "appointment"){

    }else{
      this.gotoClasses()
    }
  }

}
