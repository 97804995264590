import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-offer-terms-modal',
  templateUrl: './offer-terms-modal.page.html',
  styleUrls: ['./offer-terms-modal.page.scss'],
})
export class OfferTermsModalPage implements OnInit {

  public cartProducts;
  public additionalProducts;
  public totalProducts;
  public offerTerms;
  public totalPrice;
  public flag = false;
  public discountAmount;
  public appFlowCart;
 public offerFlowCart;
  public contactName;
  public contactNumber;
  public dateOfBirth;
  public nationalId;
  public offername;
  public theme; 
  public themeFlag;
  fromDataService: any;

  constructor(
    public modalCtrl: ModalController,
    private storage: Storage,
    private router: Router,
    private route: ActivatedRoute,
    public translateService: TranslateService,
    public ModalController: ModalController,
  ) {
    
    if (this.route.snapshot.data['special']) {
      this.fromDataService = this.route.snapshot.data['special'];
      console.log(this.fromDataService, "DATAAAAAAAA");
    }

  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.getCurrentTheme();

    this.storage.get('OFFERMODALTERMS').then((val) => {
      this.offerTerms = val;
      console.log(this.offerTerms,"VALUE IN OFFER_DETAILS IN IONVIEWDIDLOAD OF MODAL");
    });
  
    
  }

  ionViewDidLoad() {
    //this.getCurrentTheme();
    

  }

  ionViewDidLeave(){
   this.modalCtrl.dismiss(); 
  }

  

   //method to close modal
   cancel() {
     this.flag = false;
    let data = { 'flag': this.flag };
    this.modalCtrl.dismiss(data);
  }

  

  iAgree() {
    this.flag = true;
    let data = { 'flag': this.flag };
    this.storage.remove('OFFERMODALTERMS')
    this.modalCtrl.dismiss(data);
  
  }
  


  closeModal(e : any) {
    //console.log((e.target.className),'SAYLI')
    console.log((e.target.clientHeight),'SAYLI')
    if(e.target.clientHeight >= 500){
      this.modalCtrl.dismiss();
    }

  }



  getCurrentTheme() {
    this.storage.get('themeSelect').then((val) => {
      this.theme = val;
      if (this.theme == "dark-theme") {
        this.themeFlag = true;
      }
      console.log('THEME', val);
    });
  }



}
