import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {workoutvideospecial} from '../../config';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  public preLoginPages = [];
  constructor(private storage: Storage,private router: Router
      ) {

        let workoutapp =['/login','/center-selection','/select-language','/otp/memberDetails']
        let preloginfullapp =['/login','/center-selection','/gender-select','/select-language','/become-member/memberDetails','/otp/memberDetails']
        this.preLoginPages =  workoutvideospecial?workoutapp:preloginfullapp;

  
  
      }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    return this.isLoggedin().then((a) => {

      if(String(state.url).includes('call-back-url') || String(state.url).includes('settings'))
      {
        return true;
      }
      else
      
      if (!a) {

        if(!this.preLoginPages.includes(state.url))
        {
          this.router.navigate(['login'],{skipLocationChange: true,replaceUrl: true});
        }
               
        return (state.url == (workoutvideospecial?'/video-category':'/dashboard')) ? false : true;
      }
      else {
        if(this.preLoginPages.includes(state.url))
        {
          workoutvideospecial?this.router.navigate(['video-category'],{skipLocationChange: true,replaceUrl: true}):this.router.navigate(['dashboard'],{skipLocationChange: true,replaceUrl: true});
        }
        return  true;
      }
    })

}

isLoggedin()
{
  let promise = new Promise((resolve, reject) => {
    this.storage.get('login').then((val) => {
          val ? resolve(true) : resolve(false)
  });
  });
  return promise;
}

}