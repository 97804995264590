import { Injectable, NgZone } from '@angular/core';
// import { FormBuilder } from '@angular/forms';
import { PhoneValidaterService } from './phone-validater.service';
import { HttpService } from './services/http/http.service';
import { ToastController, Events, LoadingController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators, FormControl, FormGroup} from '@angular/forms';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class BuymembershipService {

  email: any;
  nationality: any;
  selectId: any;
  emergencyContactNumber: any;
  dob: any;
  duplicateNationalID: any;
  duplicateIqamaID: string;
  constructor(public toastController: ToastController,public translateService:TranslateService,  private loadingController: LoadingController,
    public storage: Storage, private HTPS: HttpService,public events :Events,public zone: NgZone, private router: Router,
      private route: ActivatedRoute,
    ) { }


 // profileupdate: FormGroup;

  ot1: string = "";
  ot2: string = "";
  ot3: string = "";
  ot4: string = "";
  OTPP: any;
  isLoading: boolean;
  invalidOtp: boolean;
  showmsg: boolean = true;
  waiver: any;
  ISOCODE
  ismobilenovalid: any;
  mobileNumberValidation: String;
  defaultCode: any;
  memberEmail: any;
  manditoryApiCalled: boolean = false;
  memberMandatoryFields: any=[];
  offerDetails: any = [];
  requiredDocFiles = [];
  imageErorMsg: string;
  emergencyContactName: any;
  MembershipDetailsCurrent: any;
  MembersdhipDetails: any;

  byPassMobileCheck: boolean;
  public MembersdhipDetailsPast = [];

  nationalitytype: any;
  showWaiver: boolean;
  Membershiptoshow: any[];

  MemberID: any;



  GetWaiverDeatils() {
    this.storage.get('tocken').then(value => {
      let tocken = value;
      this.HTPS.CartWaiverActionModalGetWaiverTerms(tocken).subscribe(response => {
        this.dismiss()

        //console.log(response.data.Waiver[0].WaiverTerms)
        let resp = response.data;
        this.waiver = resp.Waiver[0].WaiverTerms;
        
      });
    })
    this.SendOtpWaiver();
  }

  getWaiver() {
    return this.waiver
  }

  SendOtpWaiver() {
    this.showmsg = false;
    let dt = moment().format('YYYY-MM-DD HH:mm:ss');
    this.storage.get('DefaultCountryDialCode').then((vala) => {
      this.storage.get('USER_STORE').then((val) => {
        console.log("USER_STORE", val)
        let code = vala.substring(1, vala.length);
        let otpRequestBody = {
          "centerid": val.tenantID,
          "companyid": val.CompanyId,
          "isocode": this.ISOCODE || 'en-US',
          "countrycode": code,
          "mobilenumber": val.phoneNumber,
          "memberid": this.MemberID,
          "source": "waiver",
          "isuser": "false",
          "isWaiver": true,
          "waiverinfo": "",
          "time": dt
        }
        this.HTPS.withoutTokeninterSendOTP(otpRequestBody).subscribe((response) => {
          this.dismiss()
          console.log(response, 'otpid');
          this.OTPP = response.data.otpdata.otpid;
          this.showmsg = true;
          this.otpSentToast();
        });
      });
    });
  }
  async otpSentToast() {
    let otpToast
    this.translateService.get('OTPHASBEENSENTTOREGISTERMOBILENUMBER').subscribe(value => {
      otpToast = value;
    })
    let toast = await this.toastController.create({
      message: otpToast,
      duration: 2000,
      position: 'bottom'
    });
    await toast.present();
  }
  async toastLoading(message: string) {
    let toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: 'bottom'
    });
    return toast.present();
  }
  async present() {
    this.isLoading = true;
    let waitText
    await this.translateService.get('PLEASEWAIT').subscribe(value => {
      waitText = value;
      return this.loadingController.create({
        message: waitText,
        duration: 1000
      }).then(a => {
        a.present().then(() => {
          console.log('presented');
          if (!this.isLoading) {
            a.dismiss().then(() => console.log('abort presenting'));
          }
        });
      });
    })

  }

  async dismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('dismissed'));
  }

}
