import { Component, OnInit, ViewChild,NgZone } from '@angular/core';
import { ApigetService } from '../../services/apiget/apiget.service';
import { ModalController, MenuController,LoadingController } from '@ionic/angular';
import { modalController } from '@ionic/core';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { LanguageService } from '../../services/language/language.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-country-modal',
  templateUrl: './country-modal.page.html',
  styleUrls: ['./country-modal.page.scss'],
})
export class CountryModalPage implements OnInit {
  public allCountries: any = [];
  public myInput: any;
  public CountrySelected: string;
  showPop: boolean = true;
  themeName: string;
  filteterdCountries: any = [];
  @ViewChild('searchInput', { static: false }) sInput;
  defaultImage: string;


  constructor(private apiService: ApigetService,
    private translateService: TranslateService,
    public loadingCtrl: LoadingController,
    private menuCtrl: MenuController, private theme: ThemeService, public language: LanguageService, public zone:NgZone) {

  }

  ngOnInit() {
    this.GetData();
    this.defaultImage = '';
   
    //setTimeout(() => this.searchbar.focus(), 300);
   // this.menuCtrl.enable(false);

  }

  ionViewWillEnter() {
    this.presentLoading();
  }

  async presentLoading() {
    let waitText
    this.translateService.get('PLEASEWAIT').subscribe(value => {
      waitText = value;
    })
    const loading = await this.loadingCtrl.create({
      message: waitText,
      duration: 300
    });
    await loading.present().then(() => console.log('present loading'));
  }

  // Fetching All Countries Data with APIGET Service
  GetData() {
    
    this.zone.run(() => {
      this.filteterdCountries=[];
    this.apiService.apiGet().subscribe(response => {
      this.allCountries = response;
      this.filteterdCountries = response;
      this.loadingCtrl.dismiss();
       this.sInput.setFocus();
      });
    });
  }

  ionViewDidEnter(){
    this.theme.storedThemetype.then(theme => {
      this.themeName = theme;
    });
    setTimeout(() => {
      this.sInput.setFocus();
    }, 1000);
  }

  onInput($event) {
    this.search(this.myInput);
  }

  onBackPress(event) {
    if (event.keyCode == 8 || event.keyCode == 46) {
      this.GetData();
    }
  }

  // For Searching Country Based on User Input
  search(key) {
    //this.apiService.apiGet().subscribe(response => {
    //let all;
    //all = response;
    if (key && key.trim() !== '') {
      this.zone.run(() => {
      this.filteterdCountries=[];
      this.filteterdCountries = this.filterItems(key, this.allCountries).filter(function () { return true; });
      });
    }
    // });
  }
  trackByFn(index, item) {
    return index; // or item.id
  }

  filterItems(searchTerm, it) {
    return it.filter((it) => {
      return String(it.name).toLowerCase().indexOf(String(searchTerm).toLowerCase()) > -1 || String(it.Dialcode).toLowerCase().indexOf(String(searchTerm).toLowerCase()) > -1 || String(it.code).toLowerCase().indexOf(String(searchTerm).toLowerCase()) > -1 || String(it.arabicname).toLowerCase().indexOf(String(searchTerm).toLowerCase()) > -1;
    });
  }

  // Closing the Modal after selection of Country with Country Selected
  closeModal(selected) {
    const CountrySelectedState = {
      CountrySelected: selected
    };
    modalController.dismiss(CountrySelectedState);
  }

}