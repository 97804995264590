import { Component, OnInit } from '@angular/core';
import { LanguageService } from './../../app/services/language/language.service';
import { Storage ,} from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, Events } from '@ionic/angular';
import {LogoutService} from'../../app/logout.service';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss'],
})
export class LanguageSwitchComponent implements OnInit {
  selectedlanguage: any;
  public allLanguages: any;
  alertButton1Offer: any;
  alertButton2Offer: any;
  txt: any;

  constructor(
public events: Events,
public router :Router,
public languageService: LanguageService,
private alertCtrl: AlertController,
public storage :Storage,
public logout :LogoutService,
public translateService :TranslateService,
private HTTPS: HttpService

  ) { }


  
  ngOnInit() {
    setTimeout(() => {
      this.getLang() 
    }, 200);
  
  }

  async onLanguageChange(selectedLang) {
  if( this.router.url.includes('login') || this.router.url.includes('become-member') )  
{

  this.Logoutfn(selectedLang)

}else{



    this.translateService.get('CANCEL_BUTTON').subscribe(
      value => { this.alertButton1Offer = value; });

    //TRANSLATE SERVICE FOR OK BUTTON.
    this.translateService.get('PROCEED').subscribe(
      value => { this.alertButton2Offer = value; });

    this.translateService.get('LANGCHANGEMSG').subscribe(
      value => { this.txt = value; });
      const alert = await this.alertCtrl.create({
        header: this.txt,
        buttons: [{
          text: this.alertButton1Offer,
          role: 'cancel',
          handler: () => {
            this.getLang();
           }
        },
        {
          text: this.alertButton2Offer,
          handler: () => {
    
                this.Logoutfn(selectedLang)
                
         
          }
        }]
      });
      await alert.present();
    }
  }

  Logoutfn(selectedLang){
    this.selectedlanguage = selectedLang;
    let language = this.allLanguages.find(Obj => (String(Obj.name1) == String(selectedLang)));
    this.translateService.use(language.name3);
    this.allLanguages.map((obj) => {
      if (obj.name1 == selectedLang)
        obj.status = true;
      else
        obj.status = false;
    });
    if (language.name1 == "Arabic") {
      this.languageService.setLanguage(language.name3, 'rtl')
    }
    else {
      this.languageService.setLanguage(language.name3, 'ltr')
    }
    this.storage.set('SelectLang', true); //SETTING KEY TO TRUE, ONCE THE INITIAL ROOT PAGE IS SHOWN.
    this.storage.set('langSelect', language.name3).then(()=>{
      this.logout.clearData()
    }); //SETTING THE KEY FOR SELECTED LANGUAGE TO BE USED THROUGHOUT THE APP.

        if(!this.router.url.includes('become-member')  && !this.router.url.includes('otp')) {
          this.events.publish("gcmLogout");
        }
      }


  getLang() {
    this.languageService.getLanguages().subscribe((response) => {
      this.allLanguages = response;
      this.getlangg().then(val => {
        console.log( val,'Valll')
        if (val) {
          let language = this.allLanguages.find(Obj => 
            (String(Obj.name3) == val)//String(val.__zone_symbol__value["__zone_symbol__value"]))
            );
          this.selectedlanguage = language.name1;
        }
        else {
          this.selectedlanguage = this.allLanguages[0].name1;
        }
        console.log("Langgcomp",this.selectedlanguage)
      })
    });



  }


  getlangg() {
    let promise = new Promise((resolve, reject) => {
      this.storage.get('langSelect').then((val) => {
        if (val == 'en-US' || val == 'ar-SA') {
          resolve(val)
        }
        else {
          (JSON.stringify(val)).includes('en-US') ? resolve('en-US') : resolve('ar-SA')
        }
      });
    });
    return promise;
  }

}
