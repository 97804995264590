import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-notification-view-modal',
  templateUrl: './notification-view-modal.page.html',
  styleUrls: ['./notification-view-modal.page.scss'],
})

export class NotificationViewModalPage implements OnInit {

  @Input() notificationData;

  constructor(private modalController:ModalController) { }

  ngOnInit() {
    console.log(this.notificationData,'got notification data')
  }

  backtoPage() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
