
/************************************* APP Global Constants *************************************/
//[VALUES for identity]  https://idsrvleejstaging.fitnessforce.com - Staging  or  https://idsrvleej.fitnessforce.com - Prod 
// [VALUES for Apptypype]  ff3  or  leejam 
// [VALUES for companyID] 999 for leejam   1002 for ff3
//  [VALUES for Unisex]  false for leejam  true for ff3



export const LoadingTime = 10000;


export const OTPLength = 4;

// Tealiun tag account details
export const TealiumConfig ={ 
  account     : "fitness-time",
  profile     : "main",
  environment : "dev"
}



/***************************************************************************************************/


/************************************* APP Side Menu Pages  *************************************/

export const Pages = [
  {
    title: 'Home',
    url: 'dashboard',
    activeUrl: "dashboard",
    icon: 'home.svg',
    web: true,
    newkey:null
  },
  {
    title: 'My Profile',
    url: './myprofile',
    activeUrl: "myprofile",
    icon: 'use.svg',
    web: true,
    newkey:null
  },
  {
    title: 'CLASSS',
    url: './classes',
    icon: 'class.svg',
    activeUrl: "classes",
    web: true,
    newkey:'Class'
  },
  {
    title: 'BUYMEMBERSHIP',
    url: './buy-membership/type',
    activeUrl: "buy-membership/memberDetails",
    activeUrl2: "buy-membership/offerDetails",
    icon: 'buy.svg',
    web: true,
    newkey:'Buy Flow And My Cart'
  },
  {
    title: 'Gift Voucher',
    url: './gift-voucher/1',
    activeUrl: '/gift-voucher/1',
    icon: 'noun_no money_1056231.svg',
    web: false,
    newkey: 'Gift Voucher Redemption And Purchase'
  },
  { 
    title: 'GIFTAMEMBERSHIP',
    url: './gift-voucher/type',
    icon: 'gift_a_membership.svg',
    activeUrl: '/gift-voucher/type',
    web: true,
    newkey: "Gift A Membership"
  },
  {
    title: 'REDEEMAMEMBERSHIP',
    url: './redeem-membership',
    icon: 'noun_Coupon_123779.svg',
    activeUrl: 'redeem-membership',
    web: true,
    newkey: "Redeem A Membership"
  },
  {
    title: 'ONEDAYPASS',
    url: './one-day-pass-info',
    icon: 'onedaypass.svg',
    activeUrl: 'one-day-pass-info',
    web: true,
    newkey: "One day pass"
  },
  {
    title: 'Explore Clubs',
    url: './club-locator/69',
    activeUrl: "club-locator/69",
    icon: 'centers.svg',
    web: false,
    newkey:null
  },
  {
    title: 'My Schedules',
    url: './my-schedule',
    icon: 'calendar.svg',
    activeUrl: "my-schedule",
    web: true,
    newkey:null
  },
  {
    title: 'OFFERS',
    url: './offer-list',
    icon: 'offers.svg',
    activeUrl: "offer-list",
    web: true,
    newkey:'Offers list And Dashboard'
  },
  {
    title: 'WORKOUTVIDEOS',
    url: './video-category',
    icon: 'play_button.svg',
    activeUrl: "video-category",
    web: true,
    newkey: 'Workout Videos'
  },
  {
    title: 'MYMEMBERSHIP',
    url: './my-membership',
    icon: 'My_Membership.svg',
    activeUrl: "my-membership",
    web: false,
    newkey: null
  },
  {
    title: 'My Purchase History',
    url: './purchase-list',
    icon: 'purchasehistory.svg',
    activeUrl: "purchase-list",
    web: true,
    newkey:null
  },

  {
    title: 'My Cart',
    url: './cart',
    icon: 'cart.svg',
    activeUrl: "cart",
    web: false,
    newkey:'Buy Flow And My Cart'
  },
  {
    title: 'Our Trainer',
    url: './trainer-list',
    activeUrl: "trainer-list",
    icon: 'trainer.svg',
    web: true,
    newkey:'Our Trainers'
  },
  {
    title: 'FEEDBACK',
    url: './feedback-home',
    activeUrl: "feedback-home",
    icon: 'feedback.svg',
    web: true,
    newkey: null
  },

  {
    title: 'About',
    url: './about',
    icon: 'about.svg',
    activeUrl: "about",
    web: false
  },
  {
    title: 'Log Out',
    url: './logoutconfirm',
    icon: 'logout.svg',
    web: true
  }

];

/***************************************************************************************************/


/************************************* APP Feature Toggels ****************************************/

export const Onboarding = false;

export const askrating = true;

export const askRatingdays = 30;

export const workoutvideospecial = false;


/***************************************************************************************************/



/**************************************  Config Constants   ***********************************************/


export const CompanyId = 1104;
     
export const APPversion = "3.1.70";
     
export const Apptype = "ff3";  
     
export const identityURL = "https://idsrvff.fitnessforce.com";  
     
export const ClinetMobile = "B2C";
     
export const ClientCP = "localhost";
     
export const Unisex =  true;   

export const clientLogo =  'assets/iconff.png';   

     

/************************************* APP Theme Colour *************************************/


export const Themes = {

FF: {
    primary: '#7b4fa6',
    secondary: '#FCFF6C',
    tertiary: '#FE5F55',
    medium: '#BCC2C7',
    dark: '#F7F7FF',
    light: '#495867',
    text: '#000',
    white: '#fff',
    link: '#7b4fa6',
    progress: '#3d2753',
    normalColor: '#616161',
    darkColor: '#080808',
    lightColor: '#D5D5D7'
  },
  MALE: {
   primary: '#6DCFF6',
   secondary:  '#4CE0B3',
   tertiary:'#6DCFF6',
   light:  '#F4EDF2',
   medium: '#B682A5',
   dark:  '#34162A',
   text: '#000',
   white: '#fff',
   link:  '#ff8e00',
   progress: '#214F77',
   normalColor: '#616161',
   darkColor: '#080808',
   lightColor: '#D5D5D7'
 },
 FEMALE: {
   primary: '#6DCFF6',
   secondary:  '#4D9078',
   tertiary:'#6DCFF6',
   light: '#FDE8DF',
   medium: '#FCD0A2',
   dark: '#B89876',
   text:'#000',
   white: '#fff',
   link:  '#890c58',
   progress: '#214F77',
   normalColor: '#616161' ,
   darkColor: '#080808',
   lightColor: '#D5D5D7'
 }

};


/***************************************************************************************************/
  