import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';

@Component({
  selector: 'app-gif-splash',
  templateUrl: './gif-splash.page.html',
  styleUrls: ['./gif-splash.page.scss'],
})
export class GifSplashPage implements OnInit {

  constructor(public modalController: ModalController,public splashScreen: SplashScreen) {

   }

  ngOnInit() {
  }

  ionViewDidEnter() {

    this.splashScreen.hide();

    setTimeout(() => {
      this.modalController.dismiss();
    }, 7000);

  }


}
