import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpRequest,
    HttpHandler,
    HttpInterceptor
} from "@angular/common/http";
import { Observable } from "rxjs";
import { paths } from 'src/app/const';
import { Storage } from '@ionic/storage';


@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  constructor(private storage: Storage) {}
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
      ): Observable<HttpEvent<any>> {
       
        let tockenData:any
        this.storage.get('tocken').then(data =>{
          tockenData=data;
        });


    /*    const authreq=req.clone({
          setHeaders:{Autorisation:tockenData} });*/

    //     const authReq = req.clone({ setHeaders: { Authorization: authToken } });
        return next.handle(req);
      }
    }
  

