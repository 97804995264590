import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { find, findKey } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-classbutton',
  templateUrl: './classbutton.component.html',
  styleUrls: ['./classbutton.component.scss'],
})
export class ClassbuttonComponent implements OnInit {
  public _label: string;
  @Output() onClick = new EventEmitter<any>();
  public ClassDetails: any;
  public MemberClassStatusName: string;
  public message: string;
  public disabledbutton: boolean = false;
  //disableButton: boolean=false;
  public timeCounter: string;
  public removetimer: boolean = false;
  constructor(public translateService: TranslateService, private storage: Storage) { }

  ngOnInit() {
  }
  @Input()
  set classDetails(classDetails: any) {
    this.ClassDetails = classDetails;
    console.log(this.ClassDetails, 'class detailss');
    this.DefineJoinAndWithDraw();
  }


  @Input()
  set buttomText(buttomText: string) {
    this.MemberClassStatusName = this.MemberClassStatusName!;
  }
  get buttomText(): string { return this.MemberClassStatusName!; }
  @Input()
  set label(label: string) {
    this.message = this.message!;
  }
  get label(): string { return this.message!; }

  DefineJoinAndWithDraw() {
    let array = [];
    array.push(this.ClassDetails);
    let buttontxt;
    let currentDate = moment();
    buttontxt = array.find(obj => {
      let classDateDetails = moment(obj.Date + " " + obj.StartTime);
      let currentDateTime = moment();


      // bookingBefore: "minutes"
      // bookingBeforeTime: 30

      var befortimejoinclass = obj['bookingBeforeTime'];
      if (obj['bookingBefore'] == "hours") {
        befortimejoinclass = obj['bookingBeforeTime'] * 60;

      }
      else if (obj['bookingBefore'] == "minutes") {
        befortimejoinclass = obj['bookingBeforeTime'];
      }
      else {
        befortimejoinclass = 0;
      }



      //  if(befortimejoinclass==0){

      if (obj.MemberClassStatus.toLowerCase() === 'join' && moment(obj.Date + " " + obj.StartTime, "DD MMM YYYY hh:mm A").isAfter(moment())) {
        if (String(obj.CapicityStatus).toLowerCase() === 'blocked') {
          this.MemberClassStatusName = null;
          return true;
        }
        else if (+obj.totalbooked_excl_wl >= +obj.maxcapacity ) {
          this.MemberClassStatusName = 'join waitlist';
          return true;
        }
        else {
          // var befortimejoinclass=obj['bookingBeforeTime'];
          if (befortimejoinclass == 0) {
            this.translateService.get('JOIN CLASS').subscribe(value => {
              this.MemberClassStatusName = value;
              if (obj.MemberClassStatus.toLowerCase() === 'joined' && moment(obj.Date + " " + obj.StartTime, "DD MMM YYYY hh:mm A").isAfter(moment())) {

                if (obj.Can_Canc_appointment_SS == '1') {
                  this.disabledbutton = false;
                }
                else {
                  this.disabledbutton = true;
                }
                this.translateService.get('WITHDRAW').subscribe(value => {
                  this.MemberClassStatusName = value;
                })
                return true;
              }
            })
          }
         
    
          else {
            //timer starts here
            var timetobookniminutes = moment.duration(moment().diff(moment(obj.Date + ' ' + obj.StartTime).subtract(befortimejoinclass, 'minutes'))).asHours()
            console.log(timetobookniminutes, 'time')
            


            if(timetobookniminutes < 0)
            {
              //show timer
              var countDownDate = moment().add(Math.abs(timetobookniminutes), 'hours');
              console.log(countDownDate, 'countDownDate')

             
               var x = setInterval(() => {
              const diff = countDownDate.diff(moment());
              

              let res = obj.Date.split(" ");
              moment().isAfter(moment(obj.StartTime).subtract(befortimejoinclass, 'hours'));

              if (obj.StartTime <= countDownDate) {
                if (obj.MemberClassStatus.toLowerCase() === 'join' && moment(obj.Date + " " + obj.StartTime, "DD MMM YYYY hh:mm A").isAfter(moment())) {
                  if (String(obj.CapicityStatus).toLowerCase() === 'blocked') {
                    this.MemberClassStatusName = null;
                    return true;
                  } else {
                    this.timeCounter = this.label;
                  }
                }
                if (obj.MemberClassStatus.toLowerCase() === 'joined' && moment(obj.Date + " " + obj.StartTime, "DD MMM YYYY hh:mm A").isAfter(moment())) {

                  if (obj.Can_Canc_appointment_SS == '1') {
                    this.disabledbutton = false;
                  }
                  else {
                    this.disabledbutton = true;
                  }
                  this.translateService.get('WITHDRAW').subscribe(value => {
                    this.MemberClassStatusName = value;
                  })
                  return true;
                }
                else if (obj.MemberClassStatus.toLowerCase() === 'waitlist' && moment(obj.Date + " " + obj.StartTime, "DD MMM YYYY hh:mm A").isAfter(moment())) {

                  this.translateService.get('WITHDRAWFROMWAIT').subscribe(value => {
                    this.MemberClassStatusName = value;
                  })
                  if (obj.Can_Canc_appointment_SS == '1') {
                    this.disabledbutton = false
                  }
                  else if (obj.Can_Canc_appointment_SS == '0') {
                    this.disabledbutton = true
                  }

                  return true;
                }
                else {
                  this.MemberClassStatusName = null;
                  return true;
                }
              }

              if (diff <= 0) {

                //  console.log(moment( obj.Date + ' ' + obj.StartTime).subtract(befortimejoinclass, 'minutes'),'yyyyy')
                clearInterval(x);
                this.disabledbutton = false;
                this.removetimer = false;
                this.timeCounter = this.label;
                console.log("expired")
              } else {
                this.removetimer = true;
                this.disabledbutton = true;


                var strDays = "";
                var days = parseInt(moment.utc(diff).format("D")) - 1;
                days = Math.abs(days);
                if(days < 10)
                {
                  strDays = "0" + days.toString() + "d";
                }
                else
                {
                  strDays = days.toString() + "d";
                }

                this.timeCounter = strDays + " : " + moment.utc(diff).format("HH[h] : mm[m] : ss[s]")
              }

            });
              
            }
              
            
          


            this.translateService.get('JOIN CLASS').subscribe(value => {
              this.MemberClassStatusName = value;
            })
          }
          return true;
        }
      }

      else if (obj.MemberClassStatus.toLowerCase() === 'joined' && moment(obj.Date + " " + obj.StartTime, "DD MMM YYYY hh:mm A").isAfter(moment())) {

        if (obj.Can_Canc_appointment_SS == '1') {
          this.disabledbutton = false;
        }
        else {
          this.disabledbutton = true;
        }
        this.translateService.get('WITHDRAW').subscribe(value => {
          this.MemberClassStatusName = value;
        })
        return true;
      }
      else if (obj.MemberClassStatus.toLowerCase() === 'waitlist' && moment(obj.Date + " " + obj.StartTime, "DD MMM YYYY hh:mm A").isAfter(moment())) {

        this.translateService.get('WITHDRAWFROMWAIT').subscribe(value => {
          this.MemberClassStatusName = value;
        })
        if (obj.Can_Canc_appointment_SS == '1') {
          this.disabledbutton = false
        }
        else if (obj.Can_Canc_appointment_SS == '0') {
          this.disabledbutton = true
        }
        else if (+obj.totalbooked_excl_wl >= +obj.maxcapacity) {
          this.MemberClassStatusName = 'join waitlist';
          return true;
        }

        return true;
      }
      else {
        this.MemberClassStatusName = null;
        return true;
      }
    });

    let msg = array.find(obj => {
      if (String(obj.MemberClassStatus).toLowerCase() === 'no show' || String(obj.MemberClassStatus).toLowerCase() === "attended" || String(obj.MemberClassStatusName).toLowerCase() === "waitlist") {

        if (String(obj.MemberClassStatusName).toLowerCase() === "waitlist") {
          let waitcancel;
          this.translateService.get('WITHDRAWFROMWAIT').subscribe(value => {
            waitcancel = value;
          })
          return moment(obj.Date + " " + obj.StartTime, "DD MMM YYYY hh:mm A").isBefore(moment()) === true ? this.message = waitcancel : this.message = "";
        }
        else {
          return obj.MemberClassStatusName;
        }
      }
      if (String(obj.MemberClassStatus).toLowerCase() === 'join') {
        if (obj.CapicityStatus.toLowerCase() === 'blocked') {
          let capacitymsg1: string;
          this.translateService.get('CAPACITY_FULL').subscribe(value => {
            capacitymsg1 = value;
          })

          return String(obj.CapicityStatus).toLowerCase() === 'blocked' ? this.message = capacitymsg1 : this.message = '';
        }
        else {
          if (moment(obj.Date + " " + obj.StartTime, "DD MMM YYYY hh:mm A").isAfter(moment())) {
            this.message = "";
            return true;
          }
          else {
            let missedclass
            this.translateService.get('MISSEDCLASS1').subscribe(value => {
              missedclass = value;
            })
            this.message = missedclass;
            this.disabledbutton = true;
            return true;
          }
        }
      }
      else if (String(obj.MemberClassStatus).toLowerCase() === 'buy') {
        let buyText;
        this.storage.get('enabledFeatures').then(enabledFeatures => {
          this.disabledbutton = enabledFeatures.includes('Buy Flow And My Cart')? false: true;
        });

        this.translateService.get('CLASSBUY').subscribe(value => {
          buyText = value;
        })

        this.MemberClassStatusName = buyText;
        this.message = '';
        //this.label=''
        return true; 
      }
      else if (obj.MemberClassStatus.toLowerCase() === 'joined' && moment(obj.Date + " " + obj.StartTime, "DD MMM YYYY hh:mm A").isAfter(moment())) {
        this.message = '';
        return true;
      }
      else if (String(obj.MemberClassStatus).toLowerCase() === 'joined' && moment(obj.Date + " " + obj.StartTime, "DD MMM YYYY hh:mm A").isBefore(moment())) {

        //  if(this.ClassDetails['fromSchedule']) {
        if (obj.MemberClassStatusName.toLowerCase() == 'no action') {
          console.log(obj.MemberClassStatusName.toLowerCase(), 'ffff')
          this.message = '';

        }
        else if (obj.MemberClassStatusName.toLowerCase() == 'attended' || 'no show') {
          this.message = obj.MemberClassStatusName;
          this.disabledbutton = true;
          return true;

        } else {
          let missedclass
          this.translateService.get('MISSEDCLASS').subscribe(value => {
            missedclass = value;
          })
          this.message = missedclass
          this.disabledbutton = true;
          return true;
        }


      }

    });

  }


  onClickButton(event) {
    console.log('Clicked Button');
    this.onClick.emit(this.MemberClassStatusName!);
  }
}
