import { BrandAndCenterComponent } from './brand-and-center/brand-and-center.component';
import { BrowserModule } from '@angular/platform-browser';
import { ClassbuttonComponent } from './classbutton/classbutton.component';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import {LanguageSwitchComponent }from './language-switch/language-switch.component';
import { TranslateModule } from '@ngx-translate/core';
import { WaiverComponent } from './waiver/waiver.component';
import { IonicStorageModule } from '@ionic/storage';
import { ProfileComponent } from './profile/profile.component';
import { IonicSelectableModule } from 'ionic-selectable';
import { FileUploadModule } from 'ng2-file-upload';


@NgModule({
	declarations: [ClassbuttonComponent,BrandAndCenterComponent,LanguageSwitchComponent,WaiverComponent,ProfileComponent],
	imports: [IonicModule,CommonModule,FormsModule,ReactiveFormsModule,TranslateModule,IonicSelectableModule,FileUploadModule],
	exports: [ClassbuttonComponent,BrandAndCenterComponent,LanguageSwitchComponent,WaiverComponent,ProfileComponent]
})
export class ComponentsModule {}
