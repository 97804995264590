import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { ApigetService } from 'src/app/services/apiget/apiget.service';
import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx';
import { modalController } from '@ionic/core';


@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.page.html',
  styleUrls: ['./location-search.page.scss'],
})
export class LocationSearchPage implements OnInit {

  public reply;
  public addobj;
  public LoaderContentLocation;
  public ALertButtonNetwork2;
  public ALertButtonNetwork1;
  public ALertTitleNetwork;
  public ALertMessageNetwork;
  public LoaderContent;
  public uniqueArray = [];
  public point;
  private myAPI = "AIzaSyB-APjMJyEd4SdIZVAGundpDcLKJD2WpX4";
  private link = "https://maps.googleapis.com/maps/api/place/nearbysearch/json"

  private Nearlink = "https://maps.googleapis.com/maps/api/place/nearbysearch/json"
  private Citylink = "https://maps.googleapis.com/maps/api/geocode/json"
  private AutoCompleteURL = "https://maps.googleapis.com/maps/api/place/autocomplete/json?input=";

  public ifDarkTheme: boolean = false;
  public ifLightTheme: boolean = false;

  public currenttheme = "hide";

  public locations; // Stores list of Popular locations
  public listBackup = []; // COPY OF POPULAR LOCATION.

  public hasLocationAccess: boolean = false; // Stores status of Location Permission
  public City;
  public sameflag: boolean; // Flag to check if String (location) already in History

  public myInput: any; //VARIABLE TO BIND THE STRING WRITTEN IN SEARCH BAR TO NG-MODEL.

  public userSelection; // Stores User's location Selection

  public selectedCordinates;
  public latnow;
  public longnow;
  public locationMethodFlag = true; // Variable used to switch location fetching method
  public recent = {
    recentarray: []
  };

  @ViewChild('mminput',{read:'',static: false}) mminput;


  constructor(private http: HttpClient, private storage: Storage,
              private apiGetService: ApigetService, private nativeGeocoder: NativeGeocoder,
              ) { }

  ngOnInit() {
  }

  ionViewDidLoad() {

    setTimeout(() => {
      this.mminput.setFocus();
    }, 800);

  }

    // Gets List of Recent Locations
    getRecentLocations() {
      
      this.storage.get('recentLocations').then((val) => {
        if (val != null) {
          this.recent = val;
          }
      });
    }

      // Closes Current Modal and sends User's Selection as Param
  closeModal() {

    console.log('colseModal Called')
    let linkC = this.Citylink + "?address=" + this.userSelection + "&sensor=false&key=" + this.myAPI;
    console.log(linkC)
    let linkCordinates = { lat: 0, lon: 0 };

    let headers = { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' };
    let urll= encodeURI(linkC)



    if (this.userSelection) {


      this.http.get(urll, {headers: headers}).subscribe((data) => {

        console.log(data, 'BFF1');
        // let resp = JSON.parse(data.data)
        // linkCordinates.lat = resp.results[0].geometry.location.lat
        // linkCordinates.lon = resp.results[0].geometry.location.lng
        // console.log(linkCordinates)
        // modalController.dismiss({
        //   city: this.userSelection,
        //   coordinates: linkCordinates
        // }); // Sending User's Selection as Param while closing modal

      });
    } else {

      let lat = {}
      modalController.dismiss({
        city: '',
        coordinates: lat
      });

    }
}

    //TAKES THE INPUT AND BASED ON IT FILTERS THE LIST.
    onInput($event) {
      //this.getLatLongby(this.myInput) // By City
      this.getautolist();
      this.setFilteredItems(this.myInput)
    }
  
    //FILTERS THE LIST BY MODIFYING THE LIST AS PER INPUT.
    setFilteredItems(term) {
        this.locations = this.filterItemsSearch(term);
    }
  
    //MODIFIES THE LIST FROM THE SEARCH TEXT.
    filterItemsSearch(searchText) {
      if (!this.locations) return [];
      if (!searchText) return this.locations;
      searchText = searchText.toLowerCase();
      return this.locations.filter(it => {
        if (it.name.toLowerCase())
          return it.name.toLowerCase().includes(searchText);
      });
    }

     //HANDLES THE BACKPRESSED OR DELETE AND DISPLAYS THE LIST ACCORDING TO CURRENT SEARCH TEXT.
  onBackPress(event) {
    if (event.keyCode == 8 || event.keyCode == 46) {
      this.locations = this.listBackup;
    }
  }

    // Method to add new product to History (Recent Locations) , Takes input Location Name String
    addNewHistoryItem(name) {

      console.log('addHistory  Called')
      this.userSelection = name;
      this.sameflag = false;
      // Checking if already present in Recent Location
      this.recent.recentarray.forEach((e) => {
        if (e.name == name) {
          this.sameflag = true; // Setting Flag if duplicate found
        }
      });
      // Store only if previously not stored
      if (this.sameflag == false) {
        // Pushin Name to recent Location array
        if (this.recent.recentarray.length <= 3) {
          this.recent.recentarray.push({ name });
        }
        else if (this.recent.recentarray.length > 3) {
          this.recent.recentarray.splice(0, 1);
          this.recent.recentarray.push({ name });
        }
        this.storelocally(this.recent); //  method to store locally on database.
      }
  
      this.closeModal(); // Closes Current Modal
    }
  
    //method to store locally on database.
    storelocally(obj) {
      this.storage.set('recentLocations', obj); // Saves to local storage with key "recentLocations"
    }

    getNearby(lat, long, radius) {

      this.selectedCordinates = { lat: lat, long: long };
      this.link = ''
      this.reply = [];
      this.link = this.Nearlink + "?location=" + lat + "," + long + '&radius=' + radius + "&key=" + this.myAPI;
      console.log(this.link)
  
  
  
      let headers = { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' };
  
      this.http.get(this.link, {headers: headers}).subscribe((data) => {
  
  
        // let intermediate = JSON.parse(data.data)
        // //this.http.get(this.link).map(res=>res.json()).subscribe(data => {
        // this.reply = intermediate['results'];
  
        // this.uniqueArray = [];
        // for (let i = 0; i < this.reply.length; i++) {
        //   this.uniqueArray.push(this.reply[i].vicinity);
        // }
  
        // let cpy = [];
        // let index = 0
  
        // console.log(this.uniqueArray.length)
        // for (let j = 0; j <= this.uniqueArray.length; j++) {
        //   if (this.uniqueArray[j]) {
  
        //     let inter = this.uniqueArray[j].split(",")
        //     if (inter.length > 1) {
        //       for (var i = 0; i < inter.length; i++) {
  
        //         if (inter[i].trim() == "") {
        //           console.log('dp')
        //         }
        //         else {
        //           cpy.push(inter[i].trim())
        //           console.log('dp')
        //         }
  
        //       }
        //     } else {
        //       cpy.push(this.uniqueArray[j])
        //     }
        //   }
  
  
  
        // }
        // console.log(cpy);
        // let x = (cpy) => cpy.filter((v, i) => cpy.indexOf(v) === i)
  
        // console.log(x(cpy))
  
        // this.locations = cpy;
        // console.log(this.locations, "VALUE FOR THE REQUIRED NEARBY RESPONSE");
  
  
      })
  
    }

    getMyCordinates() {
      if (!this.locationMethodFlag) {
  
  
        this.apiGetService.getLocWithoutIP().subscribe(data => {
          this.selectedCordinates = { lat: data.lat, long: data.lon };
          this.userSelection = data.countryCode;
          //this.addNewHistoryItem(this.userSelection); // Add name to Recent Location (History)
          // Close Loader after location found
  
        });
  
  
      }
      else {
        //this.presentLoading();
        this.storage.get('Location').then((loc) => {
          if (loc) {
            if (loc.latitude) {
              this.latnow = loc.latitude;
            }
            if (loc.longitude) {
              this.longnow = loc.longitude;
            }
  
  
          }
  
  
        })
      }
    }

    getautolist() {
      if (this.myInput.length > 0) {
        let urll1 = this.AutoCompleteURL + this.myInput + "&location=" + 19.0728 + "," + 72.8826 + "&radius=10000&key=" + this.myAPI;
        console.log(urll1,"url")
        let urll= encodeURI(urll1)
        let headers = { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' };
  
  
  
        this.http.get(urll1, {headers: headers}).subscribe((data) => {
  
          console.log(data, 'BFF');
  
           let x = data['predictions'];
          let names = [];
          names = x.map(x => x.description)
          console.log(this.locations)
          this.locations = names;
        });
  
  
  
  
      }
  
    }

    selectmyloc() {

  
      let linkCordinates = { lat: this.latnow, lon: this.longnow };
  
      let options: NativeGeocoderOptions = {
        useLocale: true,
        maxResults: 5
      };
      this.nativeGeocoder.reverseGeocode(this.latnow, this.longnow, options)
        .then((result: NativeGeocoderResult[]) => {
          let res = result[0];
          this.userSelection = res['locality'];
          console.log(this.userSelection)
          modalController.dismiss({
            city: this.userSelection,
            coordinates: linkCordinates
          }); // Sending User's Selection as Param while closing modal
  
  
        })
        .catch((error: any) => console.log(error));
  
  
    }    
}
