import { Injectable } from '@angular/core';
import { Subject, Observable,of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DashboardUpdateService {
  Pages: any;

  constructor() { 

  }
  private appUpdateSubject = new Subject<AppUpdateState>();
  AppUpdateState = this.appUpdateSubject.asObservable();

  addPages(_pages:any) {
    console.log('in service');
    this.appUpdateSubject.next(<AppUpdateState>{added: true, pages: _pages});
  }

}

interface AppUpdateState {
  added: boolean;
  pages : any;
  }


