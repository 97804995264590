import { Injectable } from '@angular/core';
import { DataServiceService } from '../data-service/data-service.service';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DataResolverService {

  constructor(private dataService: DataServiceService) { }

  resolve(route: ActivatedRouteSnapshot) {
    let id = route.paramMap.get('id');
    return this.dataService.getData(id);
  }

}
