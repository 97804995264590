import { Component, OnInit, NgZone, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Events, ToastController, LoadingController } from '@ionic/angular';
import { HttpService } from 'src/app/services/http/http.service';
import { BuymembershipService } from 'src/app/buymembership.service';
import { Storage } from '@ionic/storage';
import { StateStore } from 'src/app/pages/buy-membership/state.store';
import { LanguageService } from 'src/app/services/language/language.service';





@Component({
  selector: 'app-waiver',
  templateUrl: './waiver.component.html',
  styleUrls: ['./waiver.component.scss','./waiver.component.rtl.scss'],
})
export class WaiverComponent implements OnInit {




  ot1: string = "";
  ot2: string = "";
  ot3: string = "";
  ot4: string = "";
  Stage: any = ['product'];
  memberMandatoryFields: any;
  public retryTimesOTP;
  enableResendOtp: boolean = true;
  ISOCODE
  showmsg: boolean = true;
  maxLimitOfOtpReached: boolean = false;
  isDocUpload: boolean = false;

  MemberID: any;
  invalidOtp: boolean;
  OTPP: any;
  waiver: any;
  otpCode: string;
  eventval: string;
  isLoading: boolean;
  showWaiverFlag: boolean;
  otpID: any;
  countryCode: any;
  offerDetails: any = [];

  val: any;
  finalOTP: string;





  constructor(public storage: Storage,public translateService: TranslateService,public languageService: LanguageService,
    public buymembershipservice:BuymembershipService, private HTPS: HttpService,
    public toastController: ToastController,public events : Events, private loadingController: LoadingController,   public zone: NgZone,  
    private router: Router,
    public stateStore:StateStore
    ) { 
      this.events.subscribe('verifyWaiverOTP',()=>{
                console.log('verify otp'),
                this.verifyCall()
              })
              this.events.subscribe('getWaiverDetails',()=>{
                  console.log('get waiver'),
                  this.buymembershipservice.GetWaiverDeatils()
                })
    }

    @Output() pushStage = new EventEmitter<any>();
    @Input()
    set showWaiver(show: boolean) {
      
      this.showWaiverFlag = show;
      console.log(show,'flagg propoerty')
      if(show == true) {
        console.log("getData")

        this.verifyCall()
        
      }
      // this.checkOTP()
      //  this.verifyCall();
     //   this.buymembershipservice.getWaiver();
      
    }
    @Input()
    set uploadWaiver(upload) {
      console.log(upload,'flagg propoerty')

      if(upload == true){
     
       // this.verifyCall()
      }
    }

  ngOnInit() {
    this.events.subscribe('uploadWaiver',(val)=> {
      console.log(val,'here i ammmm')
      if(val == true){
        console.log("getData")

        this.verifyCall()
      }

      this.retryTimesOTP = 3;
    
    })
console.log(  this.buymembershipservice.waiver ,'dataaaaaaaaa')
this.storage.get('DefaultCountryDialCode').then((vala) => {
  this.countryCode = vala.substring(1, vala.length);
  console.log("countryCode",this.countryCode)
})

this.storage.get('USER_STORE').then((val) => {

  console.log("userStore",val)
  this.val= val
})
  }

  ionViewWillEnter()
  {
    this.storage.get('DefaultCountryDialCode').then((vala) => {
      this.countryCode = vala.substring(1, vala.length);
      console.log("countryCode",this.countryCode)
    })

    this.storage.get('USER_STORE').then((val) => {

      console.log("userStore",val)
      this.val= val
    })
    // console.log("Testt",this.ot1)
    
  }


  retryOtpWaiver() {
    this.storage.get("WaiverOTPretryTime").then(val => {
      if (val) {
        if (val < this.retryTimesOTP) {
          let count = Number(val) + 1;
          // this.otpSentToast();
          this.storage.set("WaiverOTPretryTime", count).then(() => {
            this.enableResendOtp = false;
            this.buymembershipservice.SendOtpWaiver();
          }
          )
          setTimeout(() => {
            this.enableResendOtp = true;
          }, 15000);
        }
        else {
          this.maxLimitOfOtpReached = true; 
          this.translateService.get('MAXOTPREACHED').subscribe(value => {
            this.buymembershipservice.toastLoading(value);
          })
          this.enableResendOtp = false;
        }
      }
      else {
        //this.otpSentToast();
        this.storage.set("WaiverOTPretryTime", 1).then(() => {
          this.enableResendOtp = false;
          this.buymembershipservice.SendOtpWaiver();
        }
        )
        setTimeout(() => {
          this.enableResendOtp = true;
        }, 15000);
      }
    })
  }

  validateOtp() {
    let status: boolean = false;
    if (this.ot1 != "" && this.ot2 != "" && this.ot3 != "" && this.ot4 != "") {
      status = true;
    }
    else {
      status = false;
    }
    return status;
  }


    verifyCall() { 

      console.log("Inside verifyCall")
    let OTP: string = "";
    let checkvalidOtp = this.validateOtp();
    if (checkvalidOtp) {
      
      this.present();
      OTP = String(this.ot1) + String(this.ot2) + String(this.ot3) + String(this.ot4);
      // OTP = String(ot1) + String(ot2) + String(ot3) + String(ot4);
      console.log(OTP , "OTPPPPP")
      this.storage.get('tocken').then((val) => {
        let tocken = val;
        let otpcode = OTP;
          this.showmsg = false;
          this.showmsg=true;

        
            this.HTPS.OTPVerifyPost(this.buymembershipservice.OTPP, otpcode, tocken).subscribe((response) => {
          let res: any;
          console.log(response, 'otp')
          if (response.data) {
            if (response.data.error) {
              this.ot1 = ""; this.ot2 = ""; this.ot3 = ""; this.ot4 = ""; //Init all fields to be empty
              this.invalidOtp = true;
              this.storage.set('waiveraccepted', false);

              let invalidOTPToast
              this.translateService.get('INVALIDOTP').subscribe(value => {
                invalidOTPToast = value;
              })
              //this.dismi
              this.buymembershipservice.dismiss();
              let message = invalidOTPToast;
              this.toastLoading(message);
            }
            if (response.data.WaiverOtp) {
              console.log(response.data.WaiverOtp, 'waiver otp rresponse datttaa');
              if (response.data.WaiverOtp.WaiverStatus) {
                res = response.data.WaiverOtp.WaiverStatus
              }
              if (res) {
                this.storage.set('waiveraccepted', true);
                this.storage.set('WaiverStatus', true);
                // this.pushStage.emit(true)
                this.buymembershipservice.dismiss();
                  //  this.events.unsubscribe("movetonext");
                  //  this.events.subscribe("movetonext", () => {
                  //   this.zone.run(() => {
                  //     console.log("makjsfhd")
                  //     if ((this.router.url).includes("buy-membership")) {
                  //       // this.movetoCart();                       
                  //      }
                  //   })
                  // });
                  this.stateStore.onStatechange()

              }
              else {
               
                this.buymembershipservice.dismiss();
                let invalidOTPToast
                this.translateService.get('INVALIDOTP').subscribe(value => {
                  invalidOTPToast = value;
                })
                let message = invalidOTPToast;
                if(this.ot1 == "" || this.ot2 == "" || this.ot3 == "" || this.ot4 == ""){this.toastLoading(message);}
                
              }
            }
          }
        });
      });
    }
    else {
      console.log('jhdfjkhajkdjkfkj')
      let OTPEMPTYToast
      this.translateService.get('OTPCANNOTBEEMPTY').subscribe(value => {
        OTPEMPTYToast = value;
      })
      let message = OTPEMPTYToast;
      this.toastLoading(message)
    }
  }

  async otpSentToast() {
    let otpToast
    this.translateService.get('OTPHASBEENSENTTOREGISTERMOBILENUMBER').subscribe(value => {
      otpToast = value;
    })
    let toast = await this.toastController.create({
      message: otpToast,
      duration: 2000,
      position: 'bottom'
    });
    await toast.present();
  }

  

  async present() {
    this.isLoading = true;
    let waitText
    await this.translateService.get('PLEASEWAIT').subscribe(value => {
      waitText = value;
      return this.loadingController.create({
        message: waitText,
        duration: 100000
      }).then(a => {
        a.present().then(() => {
          console.log('presented');
          if (!this.isLoading) {
            a.dismiss().then(() => console.log('abort presenting'));
          }
        });
      });
    })

  }
  // async dismiss() {
  //   this.isLoading = false;
  //   return await this.loadingController.dismiss().then(() => console.log('dismissed'));
  // }
  async toastLoading(message: string) {
    let toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: 'bottom'
    });
    return toast.present();
  }
  
  
  next(nextElem, previous, event) {
    // console.log("eventVal",event['target']['value'])
    // console.log("eventVal",event['target']['value'])
   
  //  this.events.subscribe('eventVal', () => {
  //     this.();
    // });

    console.log(nextElem, 'valueeeee');
    if (event.keyCode == 8 || event.keyCode == 46) {
      previous.setFocus();
    }
    else {
      if (nextElem == 0) {
        
        this.otpCode=( String(this.ot1) + String(this.ot2) + String(this.ot3) + String(this.ot4));

        console.log("otttttp",this.otpCode)
      } else {
        nextElem.setFocus();
      }
    }
  }

}
